import React from "react";
import './../DashBoard.css';
import DashSideBar from "./../HomeComponent/DashSideBar";
import DashNavBar from "./../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import { useContext, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";



const CallPrice = () => {

    const [isLoading, setLoading] = useState(false);
    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    const navigate = useNavigate()
    
    // const isLoggedIn = () => {
    //     try {
    //       if (state.auth != null) {
    //         if (state.auth.authorisation != null || state.auth.authorisation != undefined) {
    //           return true
    //         }
    
    //       }
    //     } catch (e) {
    //       return false;
    //     }
    //   }

    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        //   }

        //   if (!isLoggedIn()) {
        //     navigate("/registering")
        //   }

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

        setLoading(true)

    }, [])



    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">

                                    <div className="page-wrapper">
                                        <div className="page-content " id="main-page-content">
                                            <div className="page-wrapper" style={{ margin: 0 }}>
                                                <div className="page-content" style={{ padding: 0 }}>
                                                    <div id="5Gzn34YgqHKgTFeoZ7SF">
                                                        <div className="card">
                                                            <div className="card-header filled">استعلام قیمت سی روز گذشته
                                                            </div>
                                                            <hr style={{ marginTop: '0.4rem', marginBottom: '0.4rem' }} />
                                                            <div className="card-body">
                                                                <table className="table table-bordered">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>محصول</th>
                                                                            <th>تعداد درخواست ( این ماه )</th>
                                                                            <th>زمان</th>
                                                                            <th>عملیات</th>
                                                                        </tr>
                                                                        {
                                                                            isLoading
                                                                                ?
                                                                                <tr>
                                                                                    <td colSpan={6} style={{ textAlign: 'center' }}>
                                                                                        <div className="loader">
                                                                                            <HashLoader
                                                                                                size={25}
                                                                                                color="#2c5d6f"
                                                                                                cssOverride={{}}
                                                                                                loading
                                                                                                speedMultiplier={1.4}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan={6} style={{ textAlign: 'center' }}>
                                                                                        hello0
                                                                                    </td>
                                                                                </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div> {/* Livewire Component wire-end:5Gzn34YgqHKgTFeoZ7SF */}
                                                    <div id="uxJjw2ymH89ZWlp17a4P">
                                                    </div> {/* Livewire Component wire-end:uxJjw2ymH89ZWlp17a4P */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                            {/* <DashFooter /> */}
                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default CallPrice;
