import React from 'react';
import "./OrdersModals.css";
import { IoIosClose } from 'react-icons/io';
import BackDrops from '../../../Tools/BackDrops';

function MyOrdersModal(props) {
    return (
        <>
            <BackDrops closeModal={props.closeModal} showModal={props.showModal} />
            <div className='product-details-modal'>

                <div className='ho-modal'>
                    <div className='header-ticket'>
                        <div className='tickethead-prop ptop-text'>
                            <span>جزئیات کامل محصول</span>
                        </div>
                        <div className='tickethead-prop ptop-icon' onClick={props.closeModal}>
                            <IoIosClose size={30} />
                        </div>
                    </div>
                </div>
                <div className="list-input" >
                    <div className="input-label res-650">
                        <div className="wid-94 flex-lable-image">
                            <label htmlFor="defaultFormControlInput" className="form-label">عکس محصول</label>
                            <div className="product-img-modal">
                                <img src={props.id.image} style={{height : "auto" , width : 110 , borderRadius : 8 }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-input" >
                    <div className="input-label" >
                        <div className="wid-94">
                            <label htmlFor="defaultFormControlInput" className="form-label">کد فنی</label>
                            <div className="form-control exre">{props.id.id}</div>
                        </div>
                    </div>
                    <div className="input-label" >
                        <div className="wid-94">
                            <label htmlFor="defaultFormControlInput" className="form-label">بازدید در سایت</label>
                            <div className="form-control exre">{props.id.id}</div>
                        </div>
                    </div>
                </div>
                <div className="list-input" >
                    <div className="input-label" >
                        <div className="wid-94">
                            <label htmlFor="defaultFormControlInput" className="form-label">ارزانترین قیمت</label>
                            <div className="form-control exre"></div>
                        </div>
                    </div>
                    <div className="input-label" >
                        <div className="wid-94">
                            <label htmlFor="defaultFormControlInput" className="form-label">قیمت شما</label>
                            <div className="form-control exre">{props.id.price}</div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

export default MyOrdersModal