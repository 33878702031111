import React, { useContext, useEffect, useState } from "react";
import DashSideBar from "./HomeComponent/DashSideBar";
import DashNavBar from "./HomeComponent/DashNavBar";
import ApexCharts from 'apexcharts';
import "./DashBoard.css";
import { MyContext } from "./ContexApi/MyContext";

const DashBoard = () => {


    const { hrefFunc, setHrefFunc } = useContext(MyContext);
    const [day, setDay] = useState({})
    const [month, setMonth] = useState({})
    const [loaded, setLoaded] = useState(true)



    var options = {

        series: [{
            name: 'فروش ماهانه',
            data: [0, 32, 45, 11, 20]
        }],

        chart: {
            width: '100%',
            type: 'area',
            fontFamily: 'yekan',
            toolbar: {
                show: false,
            },
        },
        grid: {
            borderColor: "#555",
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: false
                }
            }
        },

        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            texts: {
                show: false,
            }
        },
        fill: {
            gradient: {
                enabled: true,
                opacityFrom: 0.55,
                opacityTo: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {

            categories: ["", "شنبه1", "شنبه3", "شنبه5", ""],
            labels: {
                show: false,
            }
        },
    }
    var options1 = {

        series: [{
            name: 'آمار استرداد',
            data: [0, 32, 45, 11, 20]
        }],

        chart: {
            width: '100%',
            type: 'area',
            fontFamily: 'yekan',
            toolbar: {
                show: false,
            },
        },
        grid: {
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: false
                }
            }
        },

        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            texts: {
                show: false,
            }
        },
        fill: {
            gradient: {
                enabled: true,
                opacityFrom: 0.55,
                opacityTo: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories: ["", "شنبه1", "شنبه3", "شنبه5", ""],
            labels: {
                show: false,
            }

        },
    }
    var options2 = {

        series: [{
            name: 'فروش روزانه',
            data: [0, 32, 45, 11, 20]
        }],

        chart: {
            width: '100%',
            type: 'area',
            fontFamily: 'yekan',
            toolbar: {
                show: false,
            },
        },
        grid: {
            borderColor: "#555",
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: false
                }
            }
        },

        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            // texts: {
            //     show: false,
            // }
        },
        fill: {

            // colors: ['red'],
            gradient: {
                enabled: true,
                opacityFrom: 0.55,
                opacityTo: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories: ["", "شنبه1", "شنبه3", "شنبه5", ""],
            labels: {
                show: false,
            }
        },
        yaxis: {
            labels: {
                show: false,
            }
        },
    }


    useEffect(() => {

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)
        var chart = new ApexCharts(document.querySelector("#chart"), options);
        var chart1 = new ApexCharts(document.querySelector("#chart1"), options1);
        var chart2 = new ApexCharts(document.querySelector("#chart2"), options2);

        chart.render();
        chart1.render();
        chart2.render();

    }, [])




    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">

                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "baseline", flexWrap: "wrap" }}>
                                        <div>
                                            <div id="chart">
                                            </div>
                                        </div>
                                        <div>
                                            <div id="chart1">
                                            </div>
                                        </div>
                                        <div>
                                            <div id="chart2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* / Content */}
                            <div className="content-backdrop fade" />
                        </div>
                        {/* <div className="miuzn">
                            <DashFooter />
                        </div> */}

                    </div>

                </div>
            </div>

        </>
    );
}

export default DashBoard;