import React, { useEffect, useContext, useRef, useState } from "react";
import DashNavBar from "../../HomeComponent/DashNavBar";
import DashSideBar from "../../HomeComponent/DashSideBar";
import { MyContext } from "../../ContexApi/MyContext";



const AddOrders = () => {


    const { hrefFunc, setHrefFunc } = useContext(MyContext);
    const [imagesrc, setImagesrc] = useState('');


    const imageref = useRef()
    const fileref = useRef()

    useEffect(() => {

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)
        console.log(finaleAddress)


        imageref.current.addEventListener("click", function () {
            fileref.current.click()
        })

        fileref.current.addEventListener("change", function (event) {
            let fileReader = new FileReader();

            fileReader.onloadend = function () {
                let result = fileReader.result
                setImagesrc(result)
            }

            fileReader.readAsDataURL(event.target.files[0]);

        })



    }, [])



const notHasImage2 = !imagesrc

    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">

                                {/* Bordered Table */}
                                <div className="card mb-4">
                                    <h5 className="card-header filled" >قبل از ثبت محصول از این که محصول قبلا در سایت ثبت نشده باشد اطمینان حاصل فرمایید</h5>
                                    <hr style={{ marginTop: '0.4rem', marginBottom: '0.4rem' }} />
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label htmlFor="defaultInput" className="form-label">نام محصول درخواستی خود را وارد نمایید</label>
                                            <input id="defaultInput" className="form-control" type="text" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="defaultInput" className="form-label">برند</label>
                                            <input id="defaultInput" className="form-control" type="text" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="defaultInput" className="form-label">کشور سازنده</label>
                                            <input id="defaultInput" className="form-control" type="text" />
                                        </div>
                                        <div>
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label">توضیحات مختصر در مورد محصول</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                                        </div>
                                        <div className="main-line-space" >
                                            <input type="file" style={{ display: "none" }} ref={fileref} />
                                            <button type="button" ref={imageref} className="btn btn-success waves-effect waves-light btn-margin">افزودن تصویر</button>
                                            <div className="show-image-addorders">
                                                
                                                {!notHasImage2 && <img src={imagesrc} className="self-image-addorders" />}
                                                {notHasImage2 && <>عکسی انتخاب نشده است !!</>}
                                            </div>
                                        </div>
                                        <div className="demo-inline-spacing okdade" >
                                            <button type="button" className="btn btn-primary waves-effect waves-light addorders-btn-res" style={{ width: '20%' }}>درخواست ثبت محصول</button>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default AddOrders;
