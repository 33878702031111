import React from "react";
import './../DashBoard.css';
import { FaBars } from "react-icons/fa";
import { useContext } from "react";
import { MyContext } from "../ContexApi/MyContext";
import { Link, useNavigate } from "react-router-dom";



const DashNavBar = () => {

    const { showSideBar, setShowSideBar } = useContext(MyContext)


    const showSideBarfunc = () => {
        setShowSideBar(true)
    }

    return (
        <>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">

                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div>
                        <FaBars color="black" size={20} className="fabars-icon" onClick={showSideBarfunc} />
                    </div>
                    <ul className="navbar-nav flex-row align-items-center ms-auto">

                        <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                            <Link className="nav-link dropdown-toggle hide-arrow" to="" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <i className="ti ti-bell ti-md" />
                                <span className="badge bg-danger rounded-pill badge-notifications">5</span>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end py-0">
                                <li className="dropdown-menu-header border-bottom">
                                    <div className="dropdown-header d-flex align-items-center py-3">
                                        <h5 className="text-body mb-0 me-auto">اعلانات</h5>
                                        <Link to="" className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark all as read"><i className="ti ti-mail-opened fs-4" /></Link>
                                    </div>
                                </li>
                                <li className="dropdown-notifications-list scrollable-container">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <img src="../../assets/img/avatars/1.png" className="h-auto rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                                                    <p className="mb-0">Won the monthly best seller gold badge</p>
                                                    <small className="text-muted">1h ago</small>
                                                </div>
                                                <div className="flex-shrink-0 dropdown-notifications-actions">
                                                    <Link to="" className="dropdown-notifications-read"><span className="badge badge-dot" /></Link>
                                                    <Link to="" className="dropdown-notifications-archive"><span className="ti ti-x" /></Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <span className="avatar-initial rounded-circle bg-label-danger">CF</span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">Charles Franklin</h6>
                                                    <p className="mb-0">Accepted your connection</p>
                                                    <small className="text-muted">12hr ago</small>
                                                </div>
                                                <div className="flex-shrink-0 dropdown-notifications-actions">
                                                    <Link to="" className="dropdown-notifications-read"><span className="badge badge-dot" /></Link>
                                                    <Link to="" className="dropdown-notifications-archive"><span className="ti ti-x" /></Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <img src="../../assets/img/avatars/2.png" className="h-auto rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">New Message ✉️</h6>
                                                    <p className="mb-0">You have new message from Natalie</p>
                                                    <small className="text-muted">1h ago</small>
                                                </div>
                                                <div className="flex-shrink-0 dropdown-notifications-actions">
                                                    <Link to="" className="dropdown-notifications-read"><span className="badge badge-dot" /></Link>
                                                    <Link to="" className="dropdown-notifications-archive"><span className="ti ti-x" /></Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <span className="avatar-initial rounded-circle bg-label-success"><i className="ti ti-shopping-cart" /></span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">Whoo! You have new order 🛒</h6>
                                                    <p className="mb-0">ACME Inc. made new order $1,154</p>
                                                    <small className="text-muted">1 day ago</small>
                                                </div>
                                                <div className="flex-shrink-0 dropdown-notifications-actions">
                                                    <Link to="" className="dropdown-notifications-read"><span className="badge badge-dot" /></Link>
                                                    <Link to="" className="dropdown-notifications-archive"><span className="ti ti-x" /></Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <img src="../../assets/img/avatars/9.png" className="h-auto rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">Application has been approved 🚀</h6>
                                                    <p className="mb-0">Your ABC project application has been approved.</p>
                                                    <small className="text-muted">2 days ago</small>
                                                </div>
                                                <div className="flex-shrink-0 dropdown-notifications-actions">
                                                    <Link to="" className="dropdown-notifications-read"><span className="badge badge-dot" /></Link>
                                                    <Link to="" className="dropdown-notifications-archive"><span className="ti ti-x" /></Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <span className="avatar-initial rounded-circle bg-label-success"><i className="ti ti-chart-pie" /></span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">Monthly report is generated</h6>
                                                    <p className="mb-0">July monthly financial report is generated</p>
                                                    <small className="text-muted">3 days ago</small>
                                                </div>
                                                <div className="flex-shrink-0 dropdown-notifications-actions">
                                                    <Link to="" className="dropdown-notifications-read"><span className="badge badge-dot" /></Link>
                                                    <Link to="" className="dropdown-notifications-archive"><span className="ti ti-x" /></Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown-menu-footer border-top">
                                    <Link to="" className="dropdown-item d-flex justify-content-center text-primary p-2 h-px-40 mb-1 align-items-center">
                                        نمایش تمام اعلانات
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        {/*/ Notification */}
                        {/* User */}
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                            <Link className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                <div className="avatar avatar-online">
                                    <img src="../../assets/img/avatars/1.png" className="h-auto rounded-circle" />
                                </div>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link className="dropdown-item" to="">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar avatar-online">
                                                    <img src="../../assets/img/avatars/1.png" className="h-auto rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="fw-medium d-block">امیررضا</span>
                                                <small className="text-muted">ادمین</small>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <div className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="">
                                        <i className="ti ti-user-check me-2 ti-sm" />
                                        <span className="align-middle">پرفایل من</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="pages-account-settings-account.html">
                                        <i className="ti ti-settings me-2 ti-sm" />
                                        <span className="align-middle">تنظیمات</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/dashboard/sell-history">
                                        <span className="d-flex align-items-center align-middle">
                                            <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm" />
                                            <span className="flex-grow-1 align-middle">صورتحساب</span>
                                            <span className="flex-shrink-0 badge badge-center rounded-pill bg-label-danger w-px-20 h-px-20">3</span>
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <div className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/dashboard/tickets">
                                        <i className="ti ti-help me-2 ti-sm" />
                                        <span className="align-middle">پرسش و پاسخ</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/dashboard/price-managed">
                                        <i className="ti ti-currency-dollar me-2 ti-sm" />
                                        <span className="align-middle">قیمت گذاری</span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <div className="dropdown-divider" />
                                </li> */}
                                {/* <li>
                                    <Link className="dropdown-item" to="auth-login-cover.html" target="_blank">
                                        <i className="ti ti-logout me-2 ti-sm" />
                                        <span className="align-middle">خروج از حساب</span>
                                    </Link>
                                </li> */}
                            </ul>
                        </li>
                        {/*/ User */}
                    </ul>

                </div>
                {/* Search Small Screens */}

            </nav>

        </>
    );
};
export default DashNavBar;
