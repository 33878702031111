import React, { useContext, useEffect, useState, useRef } from "react";
import './Plans.css';
import { useNavigate } from "react-router-dom";
import { MyContext } from "../ContexApi/MyContext";
import Sidebar from "../SideBar/SideBar";
import HeaderRegister from "../SideBar/Header";
import { Valid_MeliCode, Valid_PostalCode } from "../regEX/regEX";
import FooterPlan from "../SideBar/FooterPlan";

function LocationJudge() {


  const [address, setAddress] = useState('');
  const [posta_code, setPostal_code] = useState('');
  const [manager_name, setManager_name] = useState('');
  const [manager_family, setManager_family] = useState('');
  const [manager_code, setManager_code] = useState('');

  const [notice, setNotice] = useState('');
  const [notice1, setNotice1] = useState('');
  const [notice2, setNotice2] = useState('');
  const [notice3, setNotice3] = useState('');
  const [notice4, setNotice4] = useState('');


  const navigate = useNavigate();


  const servicesForward = () => {

    if (address === "") { setNotice("آدرس شرکت را وارد نمایید") } else { setNotice("") }
    if (!Valid_PostalCode.test(posta_code)) { setNotice1("کد پستی شرکت را وارد نمایید") } else { setNotice1("") }
    if (manager_name === "") { setNotice2("نام مدیر عامل را وارد نمایید") } else { setNotice2("") }
    if (manager_family === "") { setNotice3("نام خانوادگی مدیر عامل را وارد نمایید") } else { setNotice3("") }
    if (!Valid_MeliCode.test(manager_code)) { setNotice4("کد ملی را به درستی وارد نمایید") } else { setNotice4("") }
    if (manager_name != "" && manager_family != "" && address != "" && Valid_PostalCode.test(posta_code) && Valid_MeliCode.test(manager_code)) {

      navigate('/registering/4')
    }
    else {
      return;
    }
  }




  return (
    <>
      <Sidebar />
      <HeaderRegister />
      <div className="plana plan_fix">
        <div className="map-consoles">
          <div className="banner-planreal">
            <span>شخص حقوقی</span>
          </div>
          <div className="list-input" >
            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label">آدرس</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="آدرس" aria-describedby="defaultFormControlHelp" value={address} onChange={e => setAddress(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {notice}
                </div>
              </div>

            </div>

            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label">کد پستی</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="کد پستی" aria-describedby="defaultFormControlHelp" value={posta_code} onChange={e => setPostal_code(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {notice1}
                </div>
              </div>

            </div>

          </div>
          <div className="list-input" >
            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label"> نام مدیر عامل</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="نام" aria-describedby="defaultFormControlHelp" value={manager_name} onChange={e => setManager_name(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {notice2}
                </div>
              </div>

            </div>

            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label">نام خانوادگی مدیر عامل</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="نام خانوادگی" aria-describedby="defaultFormControlHelp" value={manager_family} onChange={e => setManager_family(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {notice3}
                </div>
              </div>

            </div>

          </div>
          <div className="list-input" >
            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label">کد ملی مدیر عامل</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="کد ملی" aria-describedby="defaultFormControlHelp" value={manager_code} onChange={e => setManager_code(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {notice4}
                </div>
              </div>

            </div>

            <div className="input-label" >
            </div>

          </div>
          <div className="btn-list-input" >
            <button className="btn btn-primary btn-plan" onClick={servicesForward}>مرحله بعد</button>
          </div>
        </div>
        <FooterPlan />
      </div>
    </>
  );
}

export default LocationJudge;
