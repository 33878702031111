import React from 'react';
import "./LastPage.css";
import logo from "../images/1200-300.png";


function LastPage() {

    return (


        <div className='last-page'>
            <div className='head-lastpage'>
                <img src={logo} alt='' className='logo7' />
            </div>
            <div className='body-lastpage'>
                <div className='logo-lastpage'>
                    <h5>ثبت اطلاعات با موفقیت انجام گردید.</h5>
                </div>
                <div className='text-lastpage'>
                    <p>اطلاعات شما در حال بررسی و راستی آزمایی است.</p>
                    <p>
                        پس از بررسی و تایید اطلاعات توسط کارشناسان سامانه اینترنتی ماشین نو، می توانید به پنل خود دسترسی داشته باشید و شروع به فروش نحصولات خود در سامانه کنید.
                    </p>
                </div>
            </div>


            {/* <p>اطلاعات شما در حال بررسی و راستی آزمایی است.</p>
            <p>
                        پس از بررسی و تایید اطلاعات توسط کارشناسان سامانه اینترنتی ماشین نو، می توانید به پنل خود دسترسی داشته باشید و شروع به فروش نحصولات خود در سامانه کنید.
                    </p>
            <h5>ثبت اطلاعات با موفقیت انجام گردید.</h5>*/}

        </div>


    )
}

export default LastPage