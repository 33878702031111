import React, { useState, useEffect } from "react";
import './services.css';
import { useNavigate } from 'react-router-dom'
import Sidebar from "../SideBar/SideBar";
import { useContext } from "react";
import { MyContext } from "../ContexApi/MyContext";
import HeaderRegister from "../SideBar/Header";
import FooterPlan from "../SideBar/FooterPlan";
import serv from "./images/logo-color.png";

function Services() {
    const [select, setSelect] = useState("لوازم یدکی");

    const navigate = useNavigate();

    const { icons, setIcons } = useContext(MyContext);

    useEffect(() => {

        const addwin = window.location.href.split('/');
        const lengAdd = addwin.length;
        const numHref = addwin[lengAdd - 1];
        setIcons(numHref);

    }, [])



    const selectconnection = (event) => {

        setSelect(event.target.value)
        setTimeout(function () {
            console.log(event.target.value)

        }, 100)
    }

    const btnselect = () => {


        if (select === null) return;

        else {
            navigate("/dashboard")
        }



    }



    return (
        <>
            <div className="bg-fixet plan_fix">
                <Sidebar />
                <HeaderRegister />
                <div className="plansfield servicesz">
                    <div className="selection services">
                        <div className="maintem">
                            <div className="img-plan">
                                <img src={serv} />
                            </div>
                        </div>
                        <div className="maintem">

                            <div className="select-items dire">
                                <span className="col-bl">حوزه خدماتی که ارائه می دهید را انتخاب کنید ( به همراه کمیسیون )</span>
                            </div>
                            <div className="select-items dire">
                                <select className="btn dropdown-toggle waves-effect btnwid col-bl border-balck"  onChange={selectconnection} value={select}>
                                    <option value={'لوازم یدکی'}>لوازم یدکی  (5الی10%)</option>
                                    <option value={'اکسسوری خودرو'}>اکسسوری خودرو  (15%)</option>
                                    <option value={'ابزار آلات'}>ابزار آلات  (15%)</option>
                                </select>
                            </div>
                            <div className="select-items btndire">
                                <button className="btn btn-primary " onClick={btnselect} >
                                    مرحله بعد
                                </button>
                            </div>

                        </div>
                    </div>
                    <FooterPlan />
                </div>

            </div>

        </>
    );
}

export default Services;
