import React from "react";
import './../DashBoard.css';
import DashSideBar from "./../HomeComponent/DashSideBar";
import DashNavBar from "./../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const PriceManaged = () => {


    const [isLoading, setLoading] = useState(false);

    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    const navigate = useNavigate()

    // const isLoggedIn = () => {
    //     try {
    //       if (state.auth != null) {
    //         if (state.auth.authorisation != null || state.auth.authorisation != undefined) {
    //           return true
    //         }
    
    //       }
    //     } catch (e) {
    //       return false;
    //     }
    //   }

    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        // }
        // if (!isLoggedIn()) {
        //     navigate("/registering")
        //   }

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

    }, [])




    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                             <div className="row">

                                    <div className="col-12">
                                        <div className="card mb-4">
                                            <h5 className="card-header filled" >افزایش همه قیمت های من</h5>
                                            <hr style={{ marginTop: '0.4rem', marginBottom: '0.4rem' }} />
                                            <div className="card-body">
                                                <div className="manageicon">
                                                    <div className="managing inputmanaging">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">درصد</label>
                                                        <input type="number" className="form-control" id="defaultFormControlInput" placeholder="0" aria-describedby="defaultFormControlHelp" />
                                                    </div>
                                                    <div className="managing btnmanaging">
                                                        <button type="button" className="btn btn-success waves-effect waves-light">تایید و افزایش قیمت</button>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    {/* Label Buttons */}
                                    <div className="col-12">
                                        <div className="card mb-4">
                                            <h5 className="card-header filled">وضعیت قیمت ها </h5>
                                            <hr style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }} />
                                            <div className="card-body">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>تعداد همه قیمت های فعال</th>
                                                            <th>تعداد همه قیمت های غیر فعال</th>
                                                        </tr>
                                                        <tr>
                                                            <td>0</td>
                                                            <td>0</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="col-8 marg-top">
                                                    <span className="btn btn-success marg-ri" onClick={()=>{}}>فعال کردن همه قیمت ها</span>
                                                    <span className="btn btn-danger marg-ri" onClick={()=>{}}> غیر فعال کردن همه قیمت ها</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            {/* <DashFooter /> */}
                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default PriceManaged;
