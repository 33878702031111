import React from "react";
import './../DashBoard.css';
import DashSideBar from "../HomeComponent/DashSideBar";
import DashNavBar from "../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import { useContext , useEffect ,useState } from "react";
import { useNavigate } from "react-router-dom";




const Academy = () => {

    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    const navigate = useNavigate()

    useEffect (() => {

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress-1]
        setHrefFunc(finaleAddress)

    },[])



    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div>
                                        <div className="card">
                                            <div className="card-header"> آموزش ها </div>
                                            <div className="card-body">
                                                <table className="table table-bordered" style={{ textAlign: 'center' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>عنوان</th>
                                                            <th>ویدئو</th>
                                                        </tr>
                                                        <tr>
                                                            <td>پروفایل کاربری</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653455157721189.mp4" controls __idm_id__={163841} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>همه محصولات - محصولات</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1656218738749952.mp4" controls __idm_id__={163842} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>محصولات من - محصولات</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653455400703869.mp4" controls __idm_id__={163843} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>منقضی شده - محصولات</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653455649648975.mp4" controls __idm_id__={163844} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>افزودن محصول جدید - محصولات</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653455549350182.mp4" controls __idm_id__={163845} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>استعلام قیمت</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653457230538980.mp4" controls __idm_id__={163846} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>تیکت ها</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653455986287407.mp4" controls __idm_id__={163847}>
                                                                </video>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>مالی</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653456034782844.mp4" controls __idm_id__={163848} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>تعطیلات</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/1653456077930573.mp4" controls __idm_id__={163849}>
                                                                </video>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>قرارداد</td>
                                                            <td>
                                                                <video style={{ width: 180 }} src="https://dash.mashinno.com/files/uploads/tutorial/165345615074191.mp4" controls __idm_id__={163850}> </video>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <DashFooter /> */}
                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div>




        </>
    );
};
export default Academy;
