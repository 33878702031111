import React from 'react'
import { IoIosClose } from "react-icons/io";
import BackDrops from './BackDrops';
import { DtPicker } from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/style.css'

function HolyDaysModal(props) {
    return (

        <>
            <BackDrops closeModal={props.closeModal} showModal={props.showModal} />
            <div className='ticket-modal'>

                <div className='hemodal'>
                    <div className='header-ticket'>
                        <div className='tickethead-prop ptop-text'>
                            <span>ثبت روز تعطیل</span>
                        </div>
                        <div className='tickethead-prop ptop-icon'>
                            <IoIosClose size={30} onClick={props.closeModal} />
                        </div>
                    </div>
                </div>
                <div className='name-odd'>

                    <label htmlFor="defaultFormControlInput" className="form-label">از تاریخ :     ___ تا تاریخ :</label>

                   
                        
                    <DtPicker type='range' showWeekend local='fa' className="form-control" id="defaultFormControlInput" onChange={props.fChange} aria-describedby="defaultFormControlHelp" />

                </div>
                {/* <div className='name-odd'>

                    <label htmlFor="defaultFormControlInput" className="form-label">تا تاریخ</label>
                    <input type="date" className="form-control" id="defaultFormControlInput" onChange={props.tChange} value={props.valueTo} aria-describedby="defaultFormControlHelp" />

                </div> */}
                
                <div className='name-odd'>

                    <label className="form-label">توضیحات (غیر الزامی)</label>
                    <textarea className="form-control" onChange={props.expChange} value={props.valueExplain} rows={3} defaultValue={""} />

                </div>
                <div className='tick-modal '>
                    <button className='btn btn-primary' onClick={props.submit}>
                        ثبت
                    </button>
                </div>
            </div>
        </>
    )
}

export default HolyDaysModal;