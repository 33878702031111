import React, { useEffect, useContext, useState } from "react";
import DashNavBar from "../HomeComponent/DashNavBar";
import DashSideBar from "../HomeComponent/DashSideBar";
import { MyContext } from "../ContexApi/MyContext";
import { HashLoader } from "react-spinners";
import ProductModal from "../Tools/ProductModal";
import { useNavigate } from "react-router-dom";
// import {upLogo , downLogo} from './'



const Product = () => {


    const [exist, setExist] = useState(false);
    const [showModal, setShowModal] = useState(false)

    const closeModal = () => {
        setShowModal(false)
    }

    // const openModalfun = (id) => {
    //     console.log(id)
    //     setInformation(id)
    //     setShowModal(true)
    // }
    // const OpenModal = {}

    // SearchBar 

    let [keyword, setKeyword] = useState("")
    const [searchBarValue, setSearchBarValue] = useState("");

    // const searchFilter = (event) => {
    //     let _keyword = event.target.value
    //     setSearchBarValue(event.target.value)
    //     setKeyword(_keyword)

    //     sendRequest(type, event.target.value, selectCount)
    // };

    // const serachingHandler = () => {
    //     setLoading(true)
    //     webRequest({
    //         url: "/products/all",
    //         auth: {
    //             authorisation: {
    //                 token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5tYXNoaW5uby5jb20vdmVuZG9yL3YxL2F1dGgvY29uZmlybS1jb2RlIiwiaWF0IjoxNzA2MzM2NjQyLCJleHAiOjE3MDY2OTY2NDIsIm5iZiI6MTcwNjMzNjY0MiwianRpIjoiYUFETEFHckRNT3p5RUhiOSIsInN1YiI6IjE4MDYyMyIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.DICxekwityESMc8x2vhZ4GM8fSXjZvhKM2Z73LjuMjw",
    //             }
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setReqInfo(response.payload.records);
    //                 setLoading(false)
    //             }
    //             else if (response.success.records) {

    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });

    // }

    // const handelEnter = (event) => {
    //     if (event.key === 'Enter') {
    //         serachingHandler()
    //     }
    // }

    // SearchBar 

    // Selection

    const [type, setType] = useState();
    const [selectCount, setSelectCount] = useState();

    // const selectsType = (event) => {
    //     setType(event.target.value)
    //     setTimeout(function () {
    //         console.log(event.target.value)
    //     }, 100)

    //     sendRequest(event.target.value, keyword, selectCount)
    // }

    // const selectsCount = (event) => {
    //     setSelectCount(event.target.value)
    //     setTimeout(function () {
    //         console.log(event.target.value)
    //     }, 100)
    //     sendRequest(type, keyword, event.target.value)
    // }

    // Selection


    const [reqInfo, setReqInfo] = useState([]);
    const [select, setSelect] = useState({});
    const [isLoading, setLoading] = useState(false);
    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    const [information, setInformation] = useState("")

    const navigate = useNavigate()


    
    useEffect(() => {

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

        setLoading(true);

    }, [])






    const helper = () => {

    }


    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <ul className="nav nav-pills flex-column mb-2">
                                    <li className="nav-item d-flex justify-content-between" style={{ marginRight: "0.5rem" }}>
                                        <a className="mt-3">سفارشات</a>
                                        <div className="text-end">

                                            <button type="button" className="btn btn-primary waves-effect waves-light me-2 p-2 btncolor-updown">
                                                دانلود :    <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ps-2">
                                                    <path opacity="0.5" d="M6.28571 19C3.91878 19 2 17.1038 2 14.7647C2 12.4256 3.91878 10.5294 6.28571 10.5294C6.56983 10.5294 6.8475 10.5567 7.11616 10.6089M14.381 8.02721C14.9767 7.81911 15.6178 7.70588 16.2857 7.70588C16.9404 7.70588 17.5693 7.81468 18.1551 8.01498M7.11616 10.6089C6.88706 9.9978 6.7619 9.33687 6.7619 8.64706C6.7619 5.52827 9.32028 3 12.4762 3C15.4159 3 17.8371 5.19371 18.1551 8.01498M7.11616 10.6089C7.68059 10.7184 8.20528 10.9374 8.66667 11.2426M18.1551 8.01498C20.393 8.78024 22 10.8811 22 13.3529C22 16.0599 20.0726 18.3221 17.5 18.8722" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
L                                               <path d="M12 16V22M12 16L14 18M12 16L10 18" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </button>
                                            <button type="button" className="btn btn-primary waves-effect waves-light me-2 p-2 btncolor-updown">
                                                آپلود :    <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ps-2">
                                                    <path opacity="0.5" d="M6.28571 19C3.91878 19 2 17.1038 2 14.7647C2 12.4256 3.91878 10.5294 6.28571 10.5294C6.56983 10.5294 6.8475 10.5567 7.11616 10.6089M14.381 8.02721C14.9767 7.81911 15.6178 7.70588 16.2857 7.70588C16.9404 7.70588 17.5693 7.81468 18.1551 8.01498M7.11616 10.6089C6.88706 9.9978 6.7619 9.33687 6.7619 8.64706C6.7619 5.52827 9.32028 3 12.4762 3C15.4159 3 17.8371 5.19371 18.1551 8.01498M7.11616 10.6089C7.68059 10.7184 8.20528 10.9374 8.66667 11.2426M18.1551 8.01498C20.393 8.78024 22 10.8811 22 13.3529C22 16.0599 20.0726 18.3221 17.5 18.8722" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
L                                               <path d="M12 22V16M12 22L14 20M12 22L10 20" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>

                                        </div>

                                    </li>
                                </ul>
                                <div className="card">
                                    <div className="card-header head-flexible" >
                                        <div className="mb-3 inputsizes3">
                                            <label htmlFor="defaultInput" className="form-label">جست و جو</label>
                                            <div className="input-group input-group-merge">
                                                <span className="input-group-text" id="basic-addon-search31"><i className="ti ti-search" /></span>
                                                <input type="text" className="form-control" onKeyDown={{}} value={searchBarValue} onChange={{}} placeholder="شماره سفارش ، شماره ، نام" aria-label="Search..." aria-describedby="basic-addon-search31" />

                                            </div>
                                        </div>
                                        <div className="mb-3 inputsizes3">
                                            <label htmlFor="exampleFormControlSelect1" className="form-label">فیلتر</label>
                                            <select id="defaultSelect" className="form-select" onChange={{}} value={type}>

                                                {
                                                    Object.keys(select).map((row, index) =>
                                                        <option key={index} value={row} >{select[row]}</option>
                                                    )}
                                            </select>
                                        </div>
                                        <div className="mb-3 inputsizes3">
                                            <label onClick={helper} htmlFor="exampleFormControlSelect1" className="form-label">تعداد نمایش</label>
                                            <select id="defaultSelect" className="form-select" onChange={{}}>
                                                <option value={5}>5 پیشفرض</option>
                                                <option value={8}>8 پیشفرض</option>
                                                <option value={10}>10 پیشفرض</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                                <option value={40}>40</option>
                                                <option value={50}>50</option>
                                                <option value={60}>60</option>
                                                <option value={70}>70</option>
                                                <option value={80}>80</option>
                                                <option value={90}>90</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr style={{ marginTop: '0.1rem', marginBottom: '0.3rem' }} />
                                    <div className="card-body">


                                        {
                                            isLoading
                                                ?
                                                <div className="loader">
                                                    <HashLoader
                                                        size={25}
                                                        color="#2c5d6f"
                                                        cssOverride={{}}
                                                        loading
                                                        speedMultiplier={1.4}
                                                    />
                                                </div>
                                                :

                                                reqInfo.map((id, index) =>


                                                    <li key={index} className="list-group-item p-4 product-list-responsive">
                                                        <div className="d-flex gap-3">

                                                            <div className="flex-grow-1">
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <p className="me-3">
                                                                            <span className="text-body color-product">شماره سفارش : </span>
                                                                            <span className="text-body color-product">{id.id} </span>
                                                                        </p>
                                                                        <div className="text-muted mb-2 d-flex flex-wrap">
                                                                            <span className="me-1 color-product"> وضعیت :</span>
                                                                            {/* <span href="javascript:void(0)" className="me-3 color-product">1244133</span> */}

                                                                            {
                                                                                id.statusText === "پرداخت شده" ?
                                                                                    <span className="badge" style={{ backgroundColor: "red" }}>{id.statusText}</span> :
                                                                                    id.statusText === "تحویل شده" ?
                                                                                        <span className="badge" style={{ backgroundColor: "green" }}>{id.statusText}</span> :
                                                                                        id.statusText === "آماده ارسال" ?
                                                                                            <span className="badge" style={{ backgroundColor: "#e1e1e1" }}>{id.statusText}</span> :
                                                                                            id.statusText === "ارسال شده" ?
                                                                                                <span className="badge" style={{ backgroundColor: "orange" }}>{id.statusText}</span> :
                                                                                                id.statusText === "ارسال به انبار" ?
                                                                                                    <span className="badge" style={{ backgroundColor: "gold" }}>{id.statusText}</span> :
                                                                                                    <span className="badge" style={{ backgroundColor: "lightgreen" }}>{id.statusText}</span>
                                                                            }




                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="me-3">
                                                                            <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>مبلغ فاکتور :</span>

                                                                            <span className="text-body color-product">{id.amount}</span>
                                                                        </p>
                                                                        <p className="me-3">
                                                                            <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>تاریخ ثبت :</span>

                                                                            <span className="text-body color-product">{id.date}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <p className="me-3">
                                                                            <span className="text-body color-product">آدرس : </span>
                                                                            <span className="text-body color-product">{id.address} </span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="text-md-end" style={{ marginTop: 8 }}>
                                                                            <button type="button" className="btn btn-sm mt-md-3" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#229954", color: "white" }}>
                                                                                نمایش وضعیت سفارشات
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                    </div>
                                    {
                                        showModal ?
                                            <ProductModal
                                                showModal={showModal}
                                                closeModal={closeModal}
                                                information={information}
                                            />
                                            :
                                            null

                                    }
                                </div>
                                {/*/ Bordered Table */}

                                {/* <DashFooter /> */}
                            </div>

                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default Product;
