import React, { useEffect, useContext, useState } from "react";
import DashNavBar from "../../HomeComponent/DashNavBar";
import DashSideBar from "../../HomeComponent/DashSideBar";
import './Orders.css';
import { MyContext } from "../../ContexApi/MyContext";
import { HashLoader } from "react-spinners";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import OrdersModal from "../../Tools/OrdersModal";
import Price_list from "../../Tools/Price_list";
import MyOrdersModal from "./Modal/MyOrdersModal";
import LoadingBackdrops from "../../Tools/LoadingBackdrops";



const MyOrders = () => {

    const [reqInfo, setReqInfo] = useState('');
    const { hrefFunc, setHrefFunc } = useContext(MyContext);
    const [loaded, setLoaded] = useState(true)
    const [keyword, setKeyword] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [imageSource, setImageSource] = useState("")


    // Modal Items and prices

    const [brand_id, setBrand_id] = useState("");
    const [country_id, setCountry_id] = useState("");
    const [count, setCount] = useState("");
    const [main_price, setMain_price] = useState("");
    const [type_line_id, setType_line_id] = useState("");
    const [final_price, setFinal_price] = useState("");
    const [is_stock, setIs_stock] = useState("");
    const [description, setDescription] = useState("");
    const [expire, setExpire] = useState("");
    const [productName, setProductName] = useState([]);
    const [allPrices, setAllPrices] = useState("");
    const [image, setImage] = useState("");


    // Modal All Price

    const closeModalfun = () => {
        setShowModal(false)
    }
    const closeModalfun1 = () => {
        setShowModal1(false)
    }

    const clickedModal = () => {
    }
    const clickedModal1 = () => {
    }







    // ???????? InFo Window 
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [compeletation, setCompeletation] = useState('');

    const infoWindow = (id) => {
        // setCompeletation(id)
        // setShowInfoModal(!showInfoModal)
        // console.log(id)
    }


    // ???????? InFo Window 






    const [allPriceName, setAllPriceName] = useState('')

    // ???????????????? all --- price ""

    // const allPrice = (id) => {
    //     setLoadingTime(true)
    //     webRequest({
    //         url: "/products/price-list",
    //         auth: state.auth,
    //         data: {
    //             product_id: id.id,
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setAllPrices(response.payload)
    //                 setShowModal1(true)
    //                 setLoadingTime(false)
    //                 setAllPriceName(id.title)
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }



    // searchBar 

    const [searchBarValue, setSearchBarValue] = useState("");

    // const searchFilter = (event) => {
    //     let _keyword = event.target.value
    //     setSearchBarValue(event.target.value)
    //     setKeyword(_keyword)

    //     sendRequest(event.target.value)

    // };
    // const serachingHandler = () => {

    //     setLoaded(true)
    //     sendRequest(keyword)

    // }

    // const handeEnter = (event) => {
    //     if (event.key === 'Enter') {
    //         serachingHandler()
    //     }
    // }


    // const sendRequest = (keyword = "") => {

    //     webRequest({
    //         url: "/products/all",
    //         auth: state.auth,
    //         data: {
    //             keyword: keyword,
    //             type: 'mine'
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setReqInfo(response.payload)
    //                 setLoaded(false)
    //                 setCurrentPage(response.payload.currentPage)
    //                 setLastPage(response.payload.lastPage)
    //                 setLoadingTime(false)
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });

    // }

    // const add_to_mine = () => {


    // }


    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        // }

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

    }, [])


   
    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();

    
    //     setLoadingTime(true)
    //     webRequest({
    //         url: "/products/all",
    //         auth: state.auth,
    //         data: {
    //             page: page,
    //             type: "mine"
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setReqInfo(response.payload);
    //                 setLoaded(false)
    //                 setCurrentPage(response.payload.currentPage)
    //                 setLastPage(response.payload.lastPage)
    //                 setLoadingTime(false)
    //             }

    //         }).catch(error => {
    //             console.error('An error occurred:', error);

    //         })

    // }

    // ADD_____PRICE 

    // const addPriceModal = (id) => {
    //     setLoadingTime(true)
    //     setProductName([id.id, id.title]);
    //     allTypeProduct(id.id);
    // }

    // ADD_____PRICE 

    ///////////////////////////////////

    const [loadingTime, setLoadingTime] = useState(false)

    return (
        <>

            <LoadingBackdrops LoadingBackdrops={loadingTime} />

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <ul className="nav nav-pills flex-column flex-md-row mb-4">
                                    <li className="nav-item" style={{marginRight : "0.5rem"}}>
                                        <a className="#"> محصولات من</a>
                                    </li>
                                </ul>
                                {/* Bordered Table */}
                                <div className="card">
                                    <div className="card-header head-flexible" >
                                        <div className="input-group input-group-merge inputsizes wid-full">
                                            <span className="input-group-text" id="basic-addon-search31" onClick={{}}><i className="ti ti-search" /></span>
                                            <input type="text" className="form-control" value={searchBarValue} onKeyDown={{}} onChange={{}} placeholder="جست و جو (شناسه ، نام)" aria-label="جست و جو (شناسه ، نام)" aria-describedby="basic-addon-search31" />
                                        </div>
                                        {/* <div className="input-group input-group-merge inputsizes">
                                            <select id="defaultSelect" className="form-select">
                                                <option>به ترتیب شناسه</option>
                                                <option value={1}>بر اساس بازدید</option>
                                            </select>
                                        </div> */}
                                    </div>
                                    <ResponsivePagination
                                        current={1}
                                        total={5}
                                        onPageChange={{}}
                                        maxWidth={500}
                                    />
                                    {/* <div className="card-body">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="table-fix">شناسه</th>
                                                        <th className="table-fix">اطلاعات محصول</th>
                                                        <th className="table-fix">نام</th>
                                                        <th className="table-fix mob-res-hide">کد فنی</th>
                                                        <th className="table-fix mob-res-hide">بازدید در سایت</th>
                                                        <th className="table-fix mob-res-hide"> ارزان ترین قیمت</th>
                                                        <th className="table-fix mob-res-hide">قیمت شما</th>
                                                        <th className="table-fix">عملیات</th>
                                                    </tr>
                                                </thead>
                                                {
                                                    !reqInfo.records || loaded
                                                        ?
                                                        <tbody style={{ display: "table-caption" }}>
                                                            <div className="loader">
                                                                <HashLoader
                                                                    size={25}
                                                                    color="#2c5d6f"
                                                                    cssOverride={{}}
                                                                    loading
                                                                    speedMultiplier={1.4}
                                                                />
                                                            </div>
                                                        </tbody>
                                                        :
                                                        <>
                                                            <tbody>
                                                                {
                                                                    reqInfo.records.map((id, index) =>
                                                                        <tr key={index}>
                                                                            <td className="table-fix">
                                                                                {id.id}
                                                                            </td>
                                                                            <td className="table-fix">
                                                                                <img src={id.image} className="orders-image1" onClick={() => infoWindow(id)} />
                                                                            </td>
                                                                            <td className="table-fix mob-res-font">
                                                                                {id.title}
                                                                            </td>
                                                                            <td className="table-fix mob-res-hide">
                                                                                {id.id}
                                                                            </td>
                                                                            <td className="table-fix mob-res-hide">
                                                                                {id.title}
                                                                            </td>
                                                                            <td className="table-fix mob-res-hide">
                                                                                {id.price}
                                                                            </td>
                                                                            <td className="table-fix mob-res-hide">
                                                                                {id.timeout}
                                                                            </td>
                                                                            <td className="table-fix">
                                                                                <div className="btn-group mob-res-button-flex" role="group" aria-label="Basic example">
                                                                                    <button type="button" className="btn btn-outline-secondary waves-effect reacttion btngroup-red mob-res-button" onClick={() => addPriceModal(id)}>افزودن قیمت جدید</button>
                                                                                    <button type="button" className="btn btn-outline-secondary waves-effect reacttion btngroup-orange mob-res-button" onClick={() => deleteProduct(id.id)} >حذف</button>
                                                                                    <button type="button" className="btn btn-outline-secondary waves-effect reacttion btngroup-green mob-res-button" onClick={() => allPrice(id)} >همه قیمت ها</button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </>
                                                }
                                            </table>
                                        </div>
                                    </div> */}

                                    {
                                        loaded
                                            ?
                                            <div className="loader">
                                                <HashLoader
                                                    size={25}
                                                    color="#2c5d6f"
                                                    cssOverride={{}}
                                                    loading
                                                    speedMultiplier={1.4}
                                                />
                                            </div>
                                            :
                                            reqInfo.records.map((id, index) =>

                                                <li key={index} className="list-group-item p-4 product-list-responsive">
                                                    <div className="d-flex gap-3">
                                                        <div className="flex-shrink-0 d-flex align-items-center">
                                                            <img src={id.image} alt="google home" className="w-px-100" />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <p className="me-3">
                                                                        <a href="" className="text-body color-product">{id.title}</a>
                                                                    </p>
                                                                    <div className="text-muted mb-2 d-flex flex-wrap">
                                                                        <span className="me-1 color-product"> قیمت :</span>
                                                                        <span className="me-3 color-product">{id.price}</span>
                                                                        <span className="badge bg-label-success">موجود</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>شناسه :</span>

                                                                        <span className="text-body color-product">{id.id}</span>
                                                                    </p>
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>کد فنی :</span>

                                                                        <span className="text-body color-product">1234</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="text-md-end" style={{ marginTop: 8 }}>
                                                                        <button type="button" className="btn btn-sm mt-md-3" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#50C878", color: "white" }}>
                                                                            افزودن قیمت
                                                                        </button>
                                                                        <button type="button" className="btn btn-sm mt-md-3" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#ECF0F1", color: "#131428" }}>
                                                                            حذف محصول
                                                                        </button>
                                                                        <button type="button" className="btn btn-sm mt-md-3" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#4682B4", color: "white" }}>
                                                                            همه قیمت ها
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            )
                                    }




                                    <ResponsivePagination
                                        current={1}
                                        total={5}
                                        onPageChange={{}}
                                        maxWidth={500}
                                        className='pagination justify-content-center Paginationclass'

                                    />

                                    {/* <DashFooter /> */}
                                </div>
                                {/*/ Bordered Table */}

                            </div>
                            {
                                showModal ? <OrdersModal
                                    productName={productName}
                                    brand_id={setBrand_id}
                                    country_id={setCountry_id}
                                    count={(e) => setCount(e.target.value)}
                                    main_price={(e) => setMain_price(e.target.value)}
                                    type_line_id={setType_line_id}
                                    final_price={(e) => setFinal_price(e.target.value)}
                                    is_stock={(e) => setIs_stock(e.target.value)}
                                    description={(e) => setDescription(e.target.value)}
                                    expire={(e) => setExpire(e.target.value)}
                                    showModal={showModal}
                                    closeModal={closeModalfun}
                                    clicked={clickedModal}
                                    // image={imagefun}
                                    imageSource={imageSource}
                                /> : null
                            }
                            {
                                showModal1 ? <Price_list
                                    name={allPriceName}
                                    allPrice={allPrices}
                                    showModal={showModal1}
                                    closeModal={closeModalfun1}
                                    clicked={clickedModal1}
                                /> : null
                            }
                            {
                                showInfoModal ?

                                    <MyOrdersModal
                                        showModal={showInfoModal}
                                        closeModal={infoWindow}
                                        id={compeletation}
                                    />
                                    :
                                    null
                            }

                            <div className="content-backdrop fade" />
                        </div>

                    </div>

                </div>
            </div>







        </>
    );
};
export default MyOrders;
