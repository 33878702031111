import React, { useState } from 'react';
import RegModal from '../Tools/Modal';





function Footers() {
    const[showModal , setShowModal ] = useState(false);
    const openModal = () => {
        setShowModal(!showModal)
    }
    const showBackDrop = () => { 
        setShowModal(false)
    }
    return (
        <>
            <footer className="first-foot">
                <div className='secfood'>
                    <span>راهنمای ثبت نام</span>
                </div>
                <div className='linefood'></div>
                <div className='secfood'>
                    <span  onClick={openModal} >تماس با پشتیبانی</span>
                </div>
            </footer>
             <RegModal 
             showModal = {showModal}
             showBackDrop = {showBackDrop}  />
        </>
    )
}

export default Footers ;