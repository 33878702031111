import React, { useState, useEffect, useContext } from "react";
import './services.css';
import { useNavigate } from 'react-router-dom'
import Sidebar from "../SideBar/SideBar";
import { MyContext } from "../ContexApi/MyContext";
import HeaderRegister from "../SideBar/Header";
import FooterPlan from "../SideBar/FooterPlan";
import serv from "./images/logo-color.png";


function SellerServices() {
    const [select, setSelect] = useState('تولید کننده');
    const [sub, setSub] = useState(false);
    const [code, setCode] = useState("")

    const navigate = useNavigate();

    const { icons, setIcons } = useContext(MyContext);


    useEffect(() => {

        const addwin = window.location.href.split('/');
        const lengAdd = addwin.length;
        const numHref = addwin[lengAdd - 1];
        setIcons(numHref);



    }, [])

    const selectconnection = (event) => {

        setSelect(event.target.value)
        setTimeout(function () {
            console.log(event.target.value)

        }, 100)
    }

    const btnselect = () => {


        if (select === null) return;

        else {

            navigate("/registering/5")
        }



    }



    return (
        <>
            <div className="bg-fixet plan_fix">
                <Sidebar />
                <HeaderRegister />
                <div className="plansfield servicesz">
                    <div className="selection services">
                        <div className="maintem">
                            <div className="img-plan">
                                <img src={serv} />
                            </div>
                        </div>
                        <div className="maintem">

                            <div className="select-items dire">
                                <span className="col-bl">با چه عنوان شغلی در این حوزه فعالیت می کنید</span>
                            </div>
                            <div className="select-items dire">

                                <select className="btn dropdown-toggle waves-effect btnwid col-bl border-balck" onChange={selectconnection} value={select}>
                                    <option value={'تولید کننده'}>تولید کننده</option>
                                    <option value={'بازرگان'}>بازرگان</option>
                                    <option value={'وارد کننده'}>وارد کننده</option>
                                    <option value={'مغازه دار'}>مغازه دار</option>
                                    <option value={'آزاد'}>آزاد</option>
                                </select>
                            </div>
                            <div className="select-items btndire">
                                <button className="btn btn-primary" onClick={btnselect} >
                                    مرحله بعد
                                </button>
                            </div>

                        </div>
                    </div>
                    <FooterPlan />

                </div>

            </div>
        </>
    );
}

export default SellerServices;
