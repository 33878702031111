import React, { useEffect, useState } from 'react'
import useAppContext from '../Infrastructure/state/useAppContext';


function Countdown(props) {

  const { state } = useAppContext();

  const [seconds, setSeconds] = useState(80);

  useEffect(() => {
    if (!state.isLoaded) {
      return;
    }
    setSeconds(props.staticTime)
    if (seconds <= 0) return;

    const timer = setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000);

    // return clearTimeout(timer)

  }, [state.isLoaded, seconds])

  return (
    <>
      {seconds === 0 && <div>زمان شما به پایان رسید</div>}
      {seconds === '0' && <div>زمان شما به پایان رسید</div>}
      {!seconds === '0' && <div>زمان شما به پایان رسید</div>}
      {seconds && <div>{`" ${seconds} : 00 "`}</div>}
    </>

  )
}

export default Countdown;