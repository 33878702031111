import React from "react";
import './PhoneSubmit.css';
import { FaCar } from "react-icons/fa6";
import {HashLoader , PulseLoader} from "react-spinners"
import { IoBuild } from "react-icons/io5";



function PhoneNumber(props) {

    return (

        <div className="que-box">
            <div className="firstfield mar-45">
            <span >سلام
            {/* <IoBuild style={{marginRight : 10}} /> */}
            </span>
                <p className="card-text" style={{ alignSelf: "flex-start" }}>
                    به پنل فروشندگان فیکسیت خوش آمدید.
                </p>
            </div>
            <div className="firstfield">

                <p className="card-text" style={{fontSize : 14}}>
                    لطفا برای ثبت نام شماره تلفن خود را وارد نمایید.
                </p>
            </div>
            <div className="inputfield">
                <div style={{ width: '100%' }}>
                    <label htmlFor="defaultFormControlInput" className="form-label">شماره تلفن همراه</label>
                    <input type="text" className="form-control form-control-lg" id="defaultFormControlInput" onChange={props.phoneNumber} placeholder="" aria-describedby="defaultFormControlHelp" />
                    <div id="defaultFormControlHelp" className="form-text error-valid">
                        {props.error}
                    </div>
                </div>
            </div>
            <div className="btnfield">
                <button onClick={props.clicked} className="btn btn-phone btn-primary large">

                    {
                        props.loading ? 
                        <PulseLoader
                                color="white"
                                margin={3}
                                size={7}
                            />
                        
                        
                        : "تایید"
                    }

                </button>
            </div>
        </div>
    );
}
export default PhoneNumber;
