import React, { useEffect, useContext, useState } from "react";
import './../DashBoard.css';
import DashSideBar from "./../HomeComponent/DashSideBar";
import DashNavBar from "./../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import HolyDaysModal from "../Tools/HolyDaysModal";
import { FaTrashAlt, FaEdit, FaEye } from "react-icons/fa";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";



const HolyDays = () => {

    const [isLoading, setLoading] = useState(false);
    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    const [holyModalItems, setHolyModalItems] = useState([

    ]);




    // Modal

    const [expchanges, setExpchanges] = useState('');
    const [fchanges, setFchanges] = useState('');
    const [tchanges, setTchanges] = useState('');
    const [all, setAll] = useState([]);
    const [datee, setDate] = useState(new Date());


    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false)
    }

    const expChange = (event) => {
        setExpchanges(event.target.value)
    }
    const fChange = (event) => {
        setFchanges(event.target.value)
    }
    const tChange = (event) => {
        setTchanges(event.target.value)
    }
    const submit = () => {

        setShowModal(false)
    }


    

    const navigate = useNavigate()

    // const isLoggedIn = () => {
    //     try {
    //       if (state.auth != null) {
    //         if (state.auth.authorisation != null || state.auth.authorisation != undefined) {
    //           return true
    //         }
    
    //       }
    //     } catch (e) {
    //       return false;
    //     }
    //   }

    // Modal

    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        // }
        // if (!isLoggedIn()) {
        //     navigate("/registering")
        // }
        setLoading(false)



        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)



    }, [])


    //    { < th > وضعیت</th >
    //                                                     <th>از تاریخ</th>
    //                                                     <th>تا تاریخ</th>
    //                                                     <th>توضیحات</th>
    //                                                     <th>زمان ثبت</th>
    //                                                     <th>عملیات</th>}


    const trashHandler = () => {

    }
    const editHandler = () => {

    }
    const situHandler = () => {

    }





    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">


                                {/* Bordered Table */}
                                <div className="card">
                                    <div className="card-header head-holy pb-1" >
                                        <h5>تعطیلات</h5>
                                        <div className="demo-inline-spacing hobtn" >
                                            <button type="button" className="btn btn-success waves-effect waves-light holybtn wid-95-650" onClick={() => setShowModal(true)}>ثبت مورد جدید</button>

                                        </div>
                                    </div>

                                    <hr />



                                    <div className="card-body">

                                        {
                                            isLoading ?
                                                <div className="loader">
                                                    <HashLoader
                                                        size={25}
                                                        color="#2c5d6f"
                                                        cssOverride={{}}
                                                        loading
                                                        speedMultiplier={1.4}
                                                    />
                                                </div>
                                                :

                                                <li className="list-group-item p-4 product-list-responsive">
                                                    <div className="d-flex gap-3">

                                                        <div className="flex-grow-1">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product">وضعیت : </span>
                                                                        <span className="btn p-2 ms-2" style={{ backgroundColor: 'green', color: "white" }}>تایید</span>
                                                                    </p>
                                                                    <div className="text-muted d-flex flex-wrap mb-3">
                                                                        <span className="me-1 color-product"> تاریخ ثبت :</span>
                                                                        <span className="me-1 color-product"> 1402/12/12 </span>

                                                                    </div>
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>تاریخ تعطیلات :</span>

                                                                        <span className="text-body color-product col-12"> 1402/12/12 تا 1402/12/12</span>
                                                                    </p>
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>مدت تعطیلات :</span>

                                                                        <span className="text-body color-product">دو هفته</span>
                                                                    </p>
                                                                </div>



                                                                <div className="col-md-4">
                                                                    <div className="text-center mt-md-4" style={{ marginTop: 8 }}>
                                                                        <span className="p-2 me-md-3" onClick={trashHandler} style={{ marginTop: "0.5rem" }}>
                                                                            <FaTrashAlt size={20} color="black" />
                                                                        </span>
                                                                        <span className="p-2 me-md-3" onClick={editHandler} style={{ marginTop: "0.5rem" }}>
                                                                            <FaEdit size={20} color="black" />

                                                                        </span>
                                                                        <span className="p-2 me-md-3" onClick={situHandler} style={{ marginTop: "0.5rem" }}>
                                                                            <FaEye size={20} color="black" />

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                        }
                                    </div>
                                </div>
                                {/*/ Bordered Table */}


                                {
                                    showModal ? <HolyDaysModal closeModal={closeModal} showModal={showModal}
                                        fChange={setFchanges} tChange={tChange} expChange={expChange}
                                        valueExplain={expchanges} valueFrom={fchanges} valueTo={tchanges}
                                        submit={submit}
                                    /> : null
                                }




                            </div>
                            {/* <DashFooter /> */}
                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div >







        </>
    );
};
export default HolyDays;
