import React from "react";
import './../DashBoard.css';
import DashSideBar from "./../HomeComponent/DashSideBar";
import DashNavBar from "./../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import { useContext, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";



const SellHistory = () => {

    const [isLoading, setLoading] = useState(false);

    const { hrefFunc, setHrefFunc } = useContext(MyContext);


    const navigate = useNavigate()

    // const isLoggedIn = () => {
    //     try {
    //       if (state.auth != null) {
    //         if (state.auth.authorisation != null || state.auth.authorisation != undefined) {
    //           return true
    //         }
    
    //       }
    //     } catch (e) {
    //       return false;
    //     }
    //   }

    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        // }
        // if (!isLoggedIn()) {
        //     navigate("/registering")
        //   }

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

        setLoading(true)

    }, [])




    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">

                                <div className="card">
                                    <div className="card-header">
                                        <h5 style={{ fontSize: 14, marginBottom: 0 }}>تاریخچه</h5>
                                        <hr style={{ marginTop: '0.4rem', marginBottom: '0.6rem' }} />

                                        <div className="input-group input-group-merge inputsizes wid-full">
                                            <span className="input-group-text" id="basic-addon-search31"><i className="ti ti-search" /></span>
                                            <input type="text" className="form-control" placeholder="کلید واژه" aria-label="Search..." aria-describedby="basic-addon-search31" />
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>شماره سفارش</th>
                                                        <th>تاریخ ثبت</th>
                                                        <th>مبلغ فاکتور</th>
                                                        <th>کارمزد ماشین نو</th>
                                                        <th>امیررضا خصالی</th>
                                                    </tr>
                                                </thead>
                                                {isLoading ?
                                                    <tbody style={{ display: "table-caption" }}>
                                                        <div className="loader">
                                                            <HashLoader
                                                                size={25}
                                                                color="#2c5d6f"
                                                                cssOverride={{}}
                                                                loading
                                                                speedMultiplier={1.4}
                                                            />
                                                        </div>
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td> </td>
                                                            <td> </td>
                                                            <td></td>
                                                            <td> </td>
                                                            <td> </td>
                                                        </tr>
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            {/*content*/}
                            {/* <DashFooter /> */}
                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default SellHistory;
