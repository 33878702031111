import React from "react";
import BackDrops from "./BackDrops";




const RegModal = (props) => {



    return (
        
        <div className={`modal ${props.showModal ? 'show' : null} fade`} id="modalCenter" tabIndex={-1}
            style={{ display: `${props.showModal ? 'block' : 'none'}`, backgroundColor: 'rgba(100 , 100 , 100 , 0.5)' }} aria-modal="true" role="dialog" onClick={props.showBackDrop}>
            <BackDrops />
            <div className="modal-dialog modal-dialog-centered adding" role="document">
                <div className="modal-content wid-70">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalCenterTitle">تماس با مشاورین در پشتیبانی</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={props.showBackDrop} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="nameWithTitle" className="form-label">شماره تلفن پاسخگویی </label>
                                <div id="nameWithTitle" className="callCenter" >
                                    <span>02191301374</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
};

export default RegModal;