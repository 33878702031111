import React, {useContext} from "react";
import { MyContext } from "../ContexApi/MyContext";
import logo from "./../Register/images/FixIt_logo.png";
import './SideBar.css';
import { FaLock, FaLockOpen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const HeaderRegister = (props) => {

    const { headerProps, setHeaderProps } = useContext(MyContext);
    const { showText, setShowText } = useContext(MyContext);
    const { icons, setIcons } = useContext(MyContext);

    
    const navigate = useNavigate()

    const serviceBack =() =>{
        navigate("/registering/4")
    }
    const infoBack =() =>{
        navigate("/registering/1")
    }
    const addressBack =() =>{
        navigate("/registering/3")
    }






    return (
        <>

            <div className="header-register">
                <div className="sec-head sizes2">
                    <div className="bred-cramp" >
                        <div className={`main-headbar falocl ${icons >= 1 ? "active2" : null}`}>
                            {icons >= 1 ? <FaLockOpen className="headbar-Icon" size={12} /> : <FaLock className="headbar-Icon" size={12} />}
                        </div>
                        <div className={`main-headbar curs  ${icons >= 1 ? "active2" : null}`} onClick={infoBack}>
                            <span className="headbar-text">مشخصات</span>
                        </div>
                        <div className={`main-headbar fitnes ${icons >= 1 ? "act" : null}`}>
                            <div className="headbar-div"></div>
                        </div>
                        <div className={`main-headbar falocl ${icons >= 3 ? "active2" : null}`}>
                            {icons >= 3 ? <FaLockOpen className="headbar-Icon" size={12} /> : <FaLock className="headbar-Icon" size={12} />}                        </div>
                        <div className={`main-headbar curs  ${icons >= 3 ? "active2" : null}`} onClick={addressBack}>
                            <span className="headbar-text">آدرس</span>
                        </div>
                        <div className={`main-headbar fitnes ${icons >= 3 ? "act" : null}`}>
                            <div className="headbar-div"></div>
                        </div>
                        <div className={`main-headbar falocl ${icons >= 4 ? "active2" : null}`}>
                            {icons >= 4 ? <FaLockOpen className="headbar-Icon" size={12} /> : <FaLock className="headbar-Icon" size={12} />}                        </div>
                        <div className={`main-headbar curs  ${icons >= 4 ? "active2" : null}`} onClick={serviceBack}>
                            <span className="headbar-text">خدمات</span>
                        </div>
                        <div className={`main-headbar fitnes ${icons >= 4 ? "act" : null}`}>
                            <div className="headbar-div"></div>
                        </div>
                        <div className={`main-headbar falocl ${icons >= 6 ? "active2" : null}`}>
                            {icons >= 6 ? <FaLockOpen className="headbar-Icon" size={12} /> : <FaLock className="headbar-Icon" size={12} />}                        </div>
                        <div className={`main-headbar ${icons >= 6 ? "active2" : null}`}>
                            <span className="headbar-text">ورود</span>
                        </div>


                    </div>
                </div>

                <div className="sec-head sizes hideprops">
                    <img src={logo} className="cssImg" />
                </div>
            </div>
        </>
    );
};

export default HeaderRegister;


