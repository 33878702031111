import React, { useState, useContext, useEffect } from "react";
import Location from "./Location";
import LocationJudge from "./LocationJudge";
import { MyContext } from "../ContexApi/MyContext";
import { useNavigate } from "react-router-dom";



const Locate = () => {

    const { chaLocate, setChaLocate } = useContext(MyContext);
    const { icons, setIcons } = useContext(MyContext);
    const navigate = useNavigate();
   
    useEffect(() => {
       
        const addwin = window.location.href.split('/');
        const lengAdd = addwin.length;
        const numHref = addwin[lengAdd - 1];
        setIcons(numHref);
    }, [])

    if (chaLocate === 'RealLocation') {
        return <Location />
    }
    else if (chaLocate === 'JudgeLocation') {

        return <LocationJudge />
    }
    else {

        return navigate('/registering/1');
    }
};
export default Locate;
