import React, { useEffect, useState } from "react";
import './PhoneSubmit.css';
import PhoneNumber from "./PhoneNumber";
import SubmitionCode from "./SubmitioanCode";
import useAppContext from './../Infrastructure/state/useAppContext';
import Footer from "./SideBar/Footer";
import { Valid_Number } from "./regEX/regEX";
import LogoMain from './Register/images/FixIt_logo.png'
import { useNavigate } from "react-router-dom";


function Registering() {

  const [changereg, setChangereg] = useState('phonenumber');
  const [sub, setSub] = useState('');
  const [phone, setPhone] = useState('');
  const [reqInfo , setReqInfo] = useState('');
  const [notice, setNotice] = useState('');
  const [subnotice, setSubnotice] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [timer, setTimer] = useState();
  const Navigate = useNavigate()
  const { state, dispatch } = useAppContext();

  const backclick = () => {
    setChangereg("phonenumber")
  }



  useEffect(() => {

    if (!state.isLoaded) {
      return;
    }


    // if (state.auth != null) {
    //   if (state.auth.authorisation != undefined || state.auth.authorisation != null) {
    //     Navigate("/registering/1")
    //   }
    // }

  }, [state.isLoaded])




  const phoneclick = () => {

    if (!Valid_Number.test(phone)) { setPhoneError("شماره تلفن خود را به درستی وارد نمایید") } else { setPhoneError("") }
    if (Valid_Number.test(phone)) {
      setIsLoaded(true)
        setTimeout( () => {
          setChangereg('somthing else')
          setIsLoaded(false)
        }  , 1500)
    }
  }

  const submitclick = () => {

    setIsLoaded(true)
    setTimeout( () => {
      Navigate('/registering/1')
      setIsLoaded(false)
    }  , 1500)

  }


  if (changereg === 'phonenumber') {
    return <div className="pishlist">
      <div className="register-box">

        <div className="static">
          <img className="imghandle" src={LogoMain} alt="" />
        </div>
        <div className="changeble">
          <PhoneNumber
            clicked={phoneclick}
            phoneNumber={e => setPhone(e.target.value)}
            notice={notice}
            error={phoneError}
            loading={isLoaded}
          />
        </div>
      </div>
      <Footer />
    </div>
  }
  else {
    return <div className="pishlist">
      <div className="register-box">
        <div className="static">
          <img className="imghandle" src={LogoMain} alt="" />
        </div>
        <div className="changeble">
          <SubmitionCode
            clicked={backclick}
            confirm={e => setSub(e.target.value)}
            submit={submitclick}
            notice={subnotice}
            phone={phone}
            error={confirmError}
            loading={isLoaded}
            timer={timer}
          />
        </div>
      </div>
      <Footer />
    </div>
  };
}
export default Registering;
