import React from 'react'
import './Tools.css';



function BackDrops(props) {
  
    return (

    props.showModal ? <div className='backdrop' onClick={props.closeModal} > </div> : null

  )
}

export default BackDrops