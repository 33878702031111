import React, { useEffect, useState, useRef } from 'react'
import { IoIosClose } from 'react-icons/io';
import BackDrops from './BackDrops';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import useAppContext from "../../Infrastructure/state/useAppContext";




function OrdersModal(props) {


    const { state, dispatch } = useAppContext();
    const [imageSource, setImageSource] = useState("")


    const fileMage = useRef();
    const mainMage = useRef();
    let notHasImage2 = !imageSource;



    useEffect(() => {

        if (!state.isLoaded) {
            return;
        }

        mainMage.current.addEventListener("click", () => {
            fileMage.current.click();
        })

        fileMage.current.addEventListener("change", (event) => {


            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                let result = fileReader.result
                setImageSource(result)
                dispatch({
                    type : 'IMAGE_PRICE' ,
                    payload : result
                })
            }
            fileReader.readAsDataURL(event.target.files[0])


        })



    }, [state.isLoaded])



    return (

        <>
            <BackDrops closeModal={props.closeModal} showModal={props.showModal} />

            <div className='orders-modal'>


                <div className='ord-modal'>
                    <div className='header-ticket'>
                        <div className='tickethead-prop ptop-text'>
                            <span> افزودن قیمت به محصول  ({props.productName[1]})</span>
                        </div>
                        <div className='tickethead-prop ptop-icon'>
                            <IoIosClose size={30} onClick={props.closeModal} />
                        </div>
                    </div>
                </div>

                <div className='ord-modal'>
                    <div className='name-ord'>
                        <label htmlFor="defaultFormControlInput" className="form-label">قیمت اصلی (تومان)</label>
                        <input onChange={props.main_price} type="text" className="form-control" id="defaultFormControlInput" aria-describedby="defaultFormControlHelp" />

                    </div>
                    <div className='name-ord'>
                        <label htmlFor="defaultFormControlInput" className="form-label">قیمت با تخفیف</label>
                        <input onChange={props.final_price} type="text" className="form-control" id="defaultFormControlInput" aria-describedby="defaultFormControlHelp" />

                    </div>
                    <div className='name-ord'>
                        <label htmlFor="defaultFormControlInput" className="form-label">تعداد موجودی</label>
                        <input onChange={props.count} type="text" className="form-control" id="defaultFormControlInput" aria-describedby="defaultFormControlHelp" />
                    </div>
                </div>
                <div className='ord-modal'>
                    <div className="mb-3 name-ord">
                        <label htmlFor="defaultSelect" className="form-label">برند</label>
                        <Typeahead
                            id='title'
                            onChange={props.brand_id}
                            labelKey="title"
                            placeholder="جستجو برند ..."
                            options={state.product_data.brands}
                        />
                    </div>
                    <div className="mb-3 name-ord">
                        <label htmlFor="defaultSelect" className="form-label">کشور سازنده</label>
                        <Typeahead
                            id='title'
                            onChange={props.country_id}
                            labelKey="title"
                            placeholder="جستجو کشور سازنده ..."
                            options={state.product_data.countries}
                        />
                    </div>
                    <div className="mb-3 name-ord">
                        <label htmlFor="defaultSelect" className="form-label">گارانتی</label>
                        <Typeahead
                            id='title'
                            onChange={props.expire}
                            labelKey="title"
                            options={[]}
                        />
                    </div>
                </div>
                <div className='ord-modal'>
                    <div className='longname-ord' >
                        <label htmlFor="defaultFormControlInput" className="form-label">توضیحات</label>
                        <input type="text" className="form-control" id="defaultFormControlInput" aria-describedby="defaultFormControlHelp" onChange={props.description} />

                    </div>

                    {state.product_data.has_type ?
                        <div className="mb-3 name-ord">
                            <label htmlFor="defaultSelect" className="form-label">{state.product_data.type.title}</label>
                            <Typeahead
                                onChange={props.type_line_id}
                                id='title'
                                labelKey="title"
                                options={state.product_data.typelines}
                            />
                        </div> : null}

                </div>
                <div className='overbtn'>

                    <div className='selectgro'>
                        <div className="mb-3">
                            <label htmlFor="defaultSelect" className="form-label fon">تاریخ پایان اعتبار</label>
                            <Typeahead
                                // onChange={props.}
                                labelKey="title"
                                options={[]}
                            />
                        </div>
                        <div >
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultValue id="defaultCheck3" />
                                <label className="form-check-label" htmlFor="defaultCheck3"> امکان ارسال توسط فروشنده </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultValue id="defaultCheck3" />
                                <label className="form-check-label" htmlFor="defaultCheck3"> با احتساب ارزش افزوده </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultValue id="defaultCheck3" onClick={props.is_stock} />
                                <label className="form-check-label" htmlFor="defaultCheck3"> این کالا استوک است </label>
                            </div>


                        </div>


                    </div>
                    <div className='checkgro'>
                        <div className='unchackgro' ref={mainMage}>
                            <input type='file' ref={fileMage} style={{ display: 'none' }}/>
                            {!notHasImage2 && <img src={imageSource} style={{ height: '95%', width: '95%', borderRadius: 4 }} />}
                            {notHasImage2 && <>برای آپلود عکس اینجا کلیک کنید</>}
                        </div>

                    </div>

                </div>

                <div className='underbtn'>
                    <button className='btn custom-mine' onClick={props.clicked}>تایید</button>
                </div>


            </div>

        </>
    )
}

export default OrdersModal;



