import React from 'react';
import { IoIosClose } from 'react-icons/io';
import BackDrops from './BackDrops';
import { useEffect } from 'react';
import useAppContext from "../../Infrastructure/state/useAppContext"


function Price_list(props) {


    const { state, dispatch } = useAppContext()


    useEffect(() => {

        if (!state.isLoaded) {
            return;
        }

        console.log(props.allPrice)


    }, [state.isLoaded])





    return (



        <>
            <BackDrops closeModal={props.closeModal} showModal={props.showModal} />

            <div className='orders-modal'>
                <div className='ord-modal'>
                    <div className='header-ticket'>
                        <div className='tickethead-prop ptop-text'>
                            <span> لیست قیمت محصول ({props.name}) </span>
                        </div>
                        <div className='tickethead-prop ptop-icon' onClick={props.closeModal}>
                            <IoIosClose size={30} />
                        </div>
                    </div>
                </div>

                <div className="form-container" style={{ height: "75%" }}>
                    <table className="table table-bordered">
                        <tbody><tr style={{ fontSize: 8 }}>
                            <th>برند</th>
                            <th>کشور سازنده</th>
                            <th>قیمت ( تومان )</th>
                            <th>وضعیت</th>
                        </tr>

                            {
                                props.allPrice.map((id, index) =>

                                    <tr style={{ fontSize: 10 }}>
                                        <td>{id.vendor.title}</td>
                                        <td>{id.country.title}</td>
                                        <td style={{fontSize : 10}}>{id.final_price}</td>
                                        <td>
                                            <span className="btn btn-sm btn-success">فعال</span>
                                        </td>
                                    </tr>

                                )}




                        </tbody>



                    </table>
                </div>


                <div className='underbtn'>
                    <button className='btn custom-mine'>تایید</button>
                </div>











            </div>
        </>
    )
}

export default Price_list