import React, { useEffect, useRef } from "react";
import './PhoneSubmit.css';
import { FaAngleRight, FaPhoneVolume } from "react-icons/fa";
import { Link } from "react-router-dom";
import Countdown from "./CountDown";
import useAppContext from "../Infrastructure/state/useAppContext"
import { PulseLoader } from "react-spinners";


function SubmitionCode(props) {

    const boxFocus = useRef();
    const { state } = useAppContext();

    useEffect(() => {
        if (!state.isLoaded) {
            return;
          }
        boxFocus.current.focus();


    }, [state.isLoaded])


    return (
        <div className="que-box">
            <div className="hfirstfieldsub">
                <FaAngleRight size={20} />
                <span onClick={props.clicked} > بازگشت </span>
            </div>
            <div className="firstfieldsub">
                <span className="card-text hei-50 line-hey">
                    پیامک تایید برای شماره تلفن <span className="confirm-span">{props.phone}<Link style={{ marginRight: 10 }}><FaPhoneVolume color="#270076" size={15} /></Link></span>  ارسال گردید
                </span>
            </div>
            <div className="inputfieldsub">
                <div style={{ width: '100%' }}>
                    <label htmlFor="defaultFormControlInput" className="form-label"> کد تایید </label>
                    <input type="text" className="form-control form-control-lg" id="defaultFormControlInput" ref={boxFocus} onChange={props.confirm} placeholder="" aria-describedby="defaultFormControlHelp" />
                    <div id="defaultFormControlHelp" className="form-text error-valid">
                        {props.notice}
                    </div>
                </div>
            </div>
            <div className="firstfieldsubcode">
                <span className="card-text edi" style={{ fontSize: 12 }}>

                    <div>
                        ثانیه تا ارسال دوباره کد تایید
                    </div>
                    <div className="countdown">
                        <Countdown staticTime={props.timer} />
                    </div>


                </span>
            </div>
            <div className="btnfield">
                <button className="btn btn-phone btn-primary large " onClick={props.submit}>

                    {
                        props.loading ?
                            <PulseLoader
                                color="white"
                                margin={3}
                                size={7}
                            />


                            : "تایید"
                    }

                </button>

            </div>
        </div>
    );
}
export default SubmitionCode;
