import React, { useEffect, useContext, useState } from "react";
import DashNavBar from "../../HomeComponent/DashNavBar";
import DashSideBar from "../../HomeComponent/DashSideBar";
import './Orders.css';
import { MyContext } from "../../ContexApi/MyContext";
import { HashLoader } from "react-spinners";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import OrdersModal from "../../Tools/OrdersModal";
import LoadingBackdrops from "../../Tools/LoadingBackdrops";
import Price_list from "../../Tools/Price_list";

const AllOrders = () => {
    const [reqInfo, setReqInfo] = useState('');
    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    let [loaded, setLoaded] = useState(true)

    let [keyword, setKeyword] = useState("")
    const [showModal, setShowModal] = useState(false);
    const [brand_id, setBrand_id] = useState("");
    const [country_id, setCountry_id] = useState("");
    const [count, setCount] = useState("");
    const [main_price, setMain_price] = useState("");
    const [type_line_id, setType_line_id] = useState("");
    const [final_price, setFinal_price] = useState("");
    const [is_stock, setIs_stock] = useState("");
    const [description, setDescription] = useState("");
    const [expire, setExpire] = useState("");
    const [productName, setProductName] = useState([]);
    const [imageSource, setImageSource] = useState("")


    // const closeModalfun = () => {
    //     setShowModal(false)
    // }
    // const clickedModal = () => {

    //     addPriceRequest(productName[0], brand_id[0].id, country_id[0].id, count, main_price, type_line_id[0].id, final_price, is_stock, description, expire, state.image_Price)
    // }
    // const allTypeProduct = (id) => {

    //     webRequest({
    //         url: "/products/add-price-data",
    //         auth: state.auth,
    //         data: {
    //             product_id: id,
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 dispatch({
    //                     type: "PRODUCT",
    //                     payload: response.payload.payload
    //                 })
    //                 setShowModal(true);
    //                 setLoadingTime(false)
    //             }
    //             else {
    //                 Swal.fire(response.message)
    //                 setLoadingTime(false)

    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }

    // ???????? InFo Window 
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [compeletation, setCompeletation] = useState('');

    // const infoWindow = (id) => {
    //     setCompeletation(id)
    //     setShowInfoModal(!showInfoModal)
    //     console.log(id)
    // }
    // ???????? InFo Window 

    // const addPrice = (id) => {
    //     setLoadingTime(true)
    //     setProductName([id.id, id.title]);
    //     allTypeProduct(id.id);
    // }

    // const addPriceRequest = (id, brand_id, country_id, count, main_price, type_line_id, final_price, is_stock, description, expire, image) => {

    //     webRequest({
    //         url: "/products/add-price",
    //         auth: state.auth,
    //         data: {
    //             product_id: id,
    //             brand_id: brand_id,
    //             country_id: country_id,
    //             count: count,
    //             main_price: main_price,
    //             type_line_id: type_line_id,
    //             final_price: final_price,
    //             is_stock: is_stock,
    //             description: description,
    //             expire: expire,
    //             image: image
    //         }
    //     })
    //         .then(response => {
    //             console.log(response)
    //             if (response.success) {
    //                 Swal.fire(response.payload.message);
    //             }
    //             else if (!response.success) {
    //                 Swal.fire(response.message + " " + "دوباره تلاش کنید");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }

    // searchBar 

    const [searchBarValue, setSearchBarValue] = useState("");

    // const searchFilter = (event) => {
    //     let _keyword = event.target.value
    //     setSearchBarValue(event.target.value)
    //     setKeyword(_keyword)

    //     sendRequest(event.target.value)

    // };
    // const serachingHandler = () => {

    //     setLoaded(true)
    //     sendRequest(keyword)

    // }

    // const handeEnter = (event) => {
    //     if (event.key === 'Enter') {
    //         serachingHandler(keyword)
    //     }
    // }


    // const sendRequest = (keyword = "", page = 1) => {

    //     webRequest({
    //         url: "/products/all",
    //         auth: state.auth,
    //         data: {
    //             keyword: keyword,
    //             page: page
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setReqInfo(response.payload);
    //                 setLoaded(false)
    //                 setCurrentPage(response.payload.currentPage)
    //                 setLastPage(response.payload.lastPage)
    //             }
    //             else if (!response.success) {

    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }

    // const deleteProduct = (id) => {
    //     setLoadingTime(true)
    //     webRequest({
    //         url: "/products/add-to-mine",
    //         auth: state.auth,
    //         data: {
    //             product_id: id
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 Swal.fire(response.payload.message);
    //                 sendRequest(keyword)
    //                 setLoadingTime(false)
    //             }
    //             else if (!response.success) {
    //                 Swal.fire(response.message);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }

    // const addProduct = (id, is_in_my_products) => {
    //     setLoadingTime(true)

    //     webRequest({
    //         url: "/products/add-to-mine",
    //         auth: state.auth,
    //         data: {
    //             product_id: id
    //         }
    //     })
    //         .then(response => {
    //             console.log(response)
    //             if (response.success) {
    //                 Swal.fire(response.payload.message);
    //                 sendRequest(keyword, currentPage)
    //                 setLoadingTime(false)
    //             }
    //             else if (!response.success) {
    //                 Swal.fire("عملیات موفقیت آمیز نبود. لطفا دوباره تلاش کنید");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }

    // Pagination///////////////////////////

    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();

    // const pageChange = (page) => {
    //     setLoadingTime(true)
    //     webRequest({
    //         url: "/products/all",
    //         auth: state.auth,
    //         data: {
    //             page: page,
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setReqInfo(response.payload);
    //                 setLoaded(false)
    //                 setCurrentPage(response.payload.currentPage)
    //                 setLastPage(response.payload.lastPage)
    //                 setLoadingTime(false)
    //             }
    //         }).catch(error => {
    //             console.error('An error occurred:', error);

    //         })
    // }

    const [allPriceName, setAllPriceName] = useState('');
    const [showModal1, setShowModal1] = useState(false);
    const [allPrices, setAllPrices] = useState("");

    const closeModalfun1 = () => {
        setShowModal1(false)
    }

    // const allPrice = (id) => {
    //     setLoadingTime(true)
    //     webRequest({
    //         url: "/products/price-list",
    //         auth: state.auth,
    //         data: {
    //             product_id: id.id,
    //         }
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setAllPrices(response.payload)
    //                 setShowModal1(true)
    //                 setLoadingTime(false)
    //                 setAllPriceName(id.title)
    //             }
    //             else {
    //                 Swal.fire(response.message)
    //                 setLoadingTime(false)

    //             }
    //         })
    //         .catch((error) => {
    //             console.error('An error occurred:', error);
    //         });
    // }




    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        // }

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

        // sendRequest()

    }, [])




    const [loadingTime, setLoadingTime] = useState(false)

    return (
        <>

            <LoadingBackdrops LoadingBackdrops={loadingTime} />
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <ul className="nav nav-pills flex-column flex-md-row mb-4">
                                    <li className="nav-item" style={{marginRight : "0.5rem"}} >
                                        <a className="#">همه محصولات</a>
                                    </li>
                                </ul>
                                {/* Bordered Table */}
                                <div className="card">
                                    <div className="card-header head-flexible" >
                                        <div className="input-group input-group-merge inputsizes wid-full">
                                            <span className="input-group-text" id="basic-addon-search31" onClick={{}}><i className="ti ti-search" /></span>
                                            <input type="text" className="form-control" value={searchBarValue} onKeyDown={{}} onChange={{}} placeholder="جست و جو (شناسه ، نام)" aria-label="جست و جو (شناسه ، نام)" aria-describedby="basic-addon-search31" />
                                        </div>
                                    </div>

                                    <ResponsivePagination
                                        current={currentPage}
                                        total={lastPage}
                                        onPageChange={{}}
                                        maxWidth={300}
                                    />

                                    {
                                        loaded
                                            ?
                                            <div className="loader">
                                                <HashLoader
                                                    size={25}
                                                    color="#2c5d6f"
                                                    cssOverride={{}}
                                                    loading
                                                    speedMultiplier={1.4}
                                                />
                                            </div>
                                            :
                                            reqInfo.records.map((id, index) =>

                                                <li key={index} className="list-group-item p-4 product-list-responsive">
                                                    <div className="d-flex gap-3">
                                                        <div className="flex-shrink-0 d-flex align-items-center">
                                                            <img src={id.image} alt="google home" className="w-px-100" />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <p className="me-3">
                                                                        <a href="" className="text-body color-product">{id.title}</a>
                                                                    </p>
                                                                    <div className="text-muted mb-2 d-flex flex-wrap">
                                                                        <span className="me-1 color-product">قیمت :</span>
                                                                        <span className="me-3 color-product">{id.price}</span>
                                                                        <span className="badge bg-label-success">موجود</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>شناسه :</span>

                                                                        <span className="text-body color-product">{id.id}</span>
                                                                    </p>
                                                                    <p className="me-3">
                                                                        <span className="text-body color-product" style={{ marginLeft: "0.4rem" }}>کد فنی :</span>

                                                                        <span className="text-body color-product">1234</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="text-md-end" style={{ marginTop: 8 }}>
                                                                        <button type="button" className="btn btn-sm mt-md-3 btn-fix-allsize" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#50C878", color: "white" }}>
                                                                            افزودن قیمت
                                                                        </button>

                                                                        {
                                                                            reqInfo.records[index].is_in_my_products ?
                                                                                <button type="button" className="btn btn-sm mt-md-3 btn-fix-allsize" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#ECF0F1", color: "#131428" }}>
                                                                                    حذف محصول
                                                                                </button>

                                                                                :
                                                                                <button type="button" className="btn btn-sm mt-md-3 btn-fix-allsize" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "rgb(18 43 64)", color: "white" }}>
                                                                                    افزودن به محصولات من
                                                                                </button>
                                                                        }
                                                                        <button type="button" className="btn btn-sm mt-md-3 btn-fix-allsize" onClick={{}} style={{ marginTop: "0.5rem", marginLeft: "0.5rem", backgroundColor: "#4682B4", color: "white" }}>
                                                                            همه قیمت ها
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            )
                                    }

                                    <ResponsivePagination
                                        current={currentPage}
                                        total={lastPage}
                                        onPageChange={{}}
                                        maxWidth={300}
                                        className='pagination justify-content-center Paginationclass'

                                    />
                                </div>

                                {
                                    showModal ? <OrdersModal
                                        productName={productName} brand_id={setBrand_id} country_id={setCountry_id}
                                        count={(e) => setCount(e.target.value)}
                                        main_price={(e) => setMain_price(e.target.value)}
                                        type_line_id={setType_line_id}
                                        final_price={(e) => setFinal_price(e.target.value)}
                                        is_stock={(e) => setIs_stock(e.target.value)}
                                        description={(e) => setDescription(e.target.value)}
                                        expire={(e) => setExpire(e.target.value)} showModal={showModal}
                                        closeModal={{}} clicked={{}} imageSource={imageSource} /> : null
                                }
                                {
                                    showModal1 ? <Price_list
                                        name={allPriceName}
                                        allPrice={allPrices}
                                        showModal={showModal1}
                                        closeModal={closeModalfun1}
                                        // clicked={clickedModal1}
                                    /> : null
                                }
                                {/* {
                                    showInfoModal ? <MyOrdersModal showModal={showInfoModal}
                                        closeModal={infoWindow} id={compeletation} /> : null} */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    );
};
export default AllOrders;
