import React, { useState, useEffect } from 'react';
import RegModal from '../Tools/Modal';
import PlansModal from '../Tools/ModalPlan';
import './SideBar.css';
import { Link, useNavigate } from 'react-router-dom';
import useAppContext from "./../../Infrastructure/state/useAppContext";




function FooterPlan() {

    const [showModal, setShowModal] = useState(false);
    const { state, dispatch } = useAppContext();

    const navigate = useNavigate()

    const openModal = () => {
        setShowModal(!showModal)
    }
    const showBackDrop = () => {
        setShowModal(false)
    }
    const [showModal2, setShowModal2] = useState(false);

    const openModal2 = () => {
        dispatch({
            type: "USER_TEMP",
            payload: {
                user: false
            }
        })
        navigate("/registering")
    }

    return (
        <>
            <footer className="first-foot wid-90 margin-none">
                <div className='secfood'>
                    <span >راهنمای ثبت نام</span>
                </div>
                <div className='linefood'></div>
                <div className='secfood'>
                    <span onClick={openModal}>تماس با پشتیبانی</span>
                </div>
                <div className='linefood'></div>
                <div className='secfood'>

                    <span onClick={openModal2}>خروج از حساب</span>

                </div>
            </footer>
            <RegModal
                showModal={showModal}
                showBackDrop={showBackDrop} />
        </>
    )
}

export default FooterPlan