import React, { useContext, useEffect } from "react";
import './Plans.css';
import { MyContext } from "../ContexApi/MyContext";
import PlanA1 from "./PlanA1";
import PlanB1 from "./PlanB1";
import Sidebar from "../SideBar/SideBar";
import HeaderRegister from "../SideBar/Header";
import { useNavigate } from "react-router-dom";



function Equal() {

    const navigate = useNavigate();

    const { equalPlan, setEqualPlan } = useContext(MyContext);
    const { icons, setIcons } = useContext(MyContext);

    useEffect(() => {

        const addwin = window.location.href.split('/');
        const lengAdd = addwin.length;
        const numHref = addwin[lengAdd - 1];
        setIcons(numHref);


    }, [])

    if (equalPlan === 'real') {
        return (
            <>
                <Sidebar />
                <HeaderRegister />
                <PlanA1 />
            </>

        )
    }
    else if (equalPlan === 'judge') {
        return (
            <>
                <Sidebar />
                <HeaderRegister />

                <PlanB1 />
            </>

        )
    }
    else {
        return navigate('/registering/1');
    }


}

export default Equal;
