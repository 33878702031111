import React, { useContext, useEffect, useState } from "react";
import './Plans.css';
import { useNavigate } from "react-router-dom";
import { MyContext } from "../ContexApi/MyContext";
import Sidebar from "../SideBar/SideBar";
import { FaWindows } from "react-icons/fa6";
import HeaderRegister from "../SideBar/Header";
import { Valid_PostalCode } from "../regEX/regEX";
import FooterPlan from "../SideBar/FooterPlan";

function Location() {

  const navigate = useNavigate();

  const [address, setAddress] = useState('');
  const [posta_code, setPostal_code] = useState('');
  const [noteadd, setNoteadd] = useState("");
  const [notecode, setNotecode] = useState("");





  const servicesForward = () => {


    if (address === "") { setNoteadd('آدرس خود را وارد کنید') }
    else { setNoteadd("") }
    if (!Valid_PostalCode.test(posta_code)) {
      setNotecode("کد پستی خود را به درستی وارد نمایید")
    } else setNotecode("")
    if (address != "" && Valid_PostalCode.test(posta_code)) {
      
      navigate('/registering/4')
    }
    else {
      return;
    }
  }


  return (
    <>
      <Sidebar />
      <HeaderRegister />
      <div className="plana plan_fix">
        <div className="map-consoles">
          <div className="banner-planreal">
            <span>شخص حقیقی</span>
          </div>
          <div className="list-input" >
            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label">آدرس</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="آدرس" aria-describedby="defaultFormControlHelp" value={address} onChange={e => setAddress(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {noteadd}
                </div>
              </div>

            </div>

            <div className="input-label imgLoaded" >

            </div>

          </div>
          <div className="list-input" >
            <div className="input-label" >
              <div className="wid-94">
                <label htmlFor="defaultFormControlInput" className="form-label">کد پستی</label>
                <input type="text" className="form-control" id="defaultFormControlInput" placeholder="کد پستی" aria-describedby="defaultFormControlHelp" value={posta_code} onChange={e => setPostal_code(e.target.value)} />
                <div id="defaultFormControlHelp" className="form-text error-valid">
                  {notecode}
                </div>
              </div>
            </div>
            <div className="input-label imloaded1" >
              {/* <div className="imageShow ">
              <img src="" alt="" className="showImmagecart" ref={mailCartImage} />
            </div> */}
            </div>
          </div>
          <div className="btn-list-input" >
            <button className="btn btn-primary btn-plan" onClick={servicesForward} >مرحله بعد</button>
          </div>
        </div>
        <FooterPlan />
      </div>
    </>
  );
}

export default Location;
