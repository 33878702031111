import React, { useState, useContext } from "react";
import "./SideBar.css";
import { FaLock, FaLockOpen, FaCalendarTimes, FaProjectDiagram, FaLayerGroup, FaAddressBook, FaSolarPanel } from 'react-icons/fa';
import { } from 'react-icons/fa6';
import { MyContext } from "../ContexApi/MyContext";

const Sidebar = (props) => {

    const [infoLock, setInfoLock] = useState(false);
    const [addressLock, setAddressLock] = useState(false);
    const [servicesLock, setServicesLock] = useState(false);
    const [panelLock, setPanelLock] = useState(false);
    const { icons, setIcons } = useContext(MyContext);

    return (
        <div className="sidebar">
            <div className="second-sidebar">
                <div id="1" className={`list-sidebar ${icons >= 1 ? "active2" : null} `}>
                    {icons >= 1 ? <FaLockOpen className="interist" size={30} /> : <FaLock className="interist" size={30} />}
                    <span>اطلاعات</span>
                </div>
                <div id="11" className={`list2-sidebar ${icons >= 1 ? "active2" : null}`}>
                    <FaCalendarTimes className="interist" size={25} />
                    <span>مشخصات فردی</span>
                </div>
                <div id="12" className={`list2-sidebar ${icons >= 2 ? "active2" : null} `}>
                    <FaProjectDiagram className="interist" size={25} />
                    <span>اطلاعات تکمیلی</span>
                </div>
                <div id="2" className={`list-sidebar ${icons >= 3 ? "active2" : null}`}>
                    {icons >= 3 ? <FaLockOpen className="interist" size={30} /> : <FaLock className="interist" size={30} />}
                    <span>آدرس</span>
                </div>
                <div id="21" className={`list2-sidebar ${icons >= 3 ? "active2" : null}`}>
                    <FaAddressBook className="interist" size={25} />
                    <span>آدرس محل سکونت</span>
                </div>
                <div id="3" className={`list-sidebar ${icons >= 4 ? "active2" : null}`}>
                    {icons >= 4 ? <FaLockOpen className="interist" size={30} /> : <FaLock className="interist" size={30} />}
                    <span>خدمات</span>
                </div>
                <div id="31" className={`list2-sidebar ${icons >= 4 ? "active2" : null}`}>
                    <FaLayerGroup className="interist" size={25} />
                    <span>کسب و کار</span>
                </div>
                <div id="32" className={`list2-sidebar ${icons >= 5 ? "active2" : null}`}>
                    <FaCalendarTimes className="interist" size={25} />
                    <span> نوع خدمات</span>
                </div>
                <div id="4" className={`list-sidebar ${icons >= 6 ? "active2" : null}`}>
                    <FaSolarPanel className="interist" size={40} />
                    <span className="panel-icon">ورود به پنل</span>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;