import React, { useState, useEffect, useRef } from "react";
import './Plans.css';
import { useNavigate } from 'react-router-dom'
import { MyContext } from "../ContexApi/MyContext";
import { useContext } from "react";
import FooterPlan from "../SideBar/FooterPlan";
import { Valid_MeliCode, Valid_Number } from "../regEX/regEX";
import { FaFolderPlus } from "react-icons/fa";

function PlanA1() {

  const [user_name, setUser_name] = useState('');
  const [user_family, setUser_family] = useState('');
  const [user_code, setUser_code] = useState('');
  const [user_number, setUser_number] = useState('');
  const [notice_name, setNotice_name] = useState('');
  const [notice_family, setNotice_family] = useState('');
  const [notice_code, setNotice_code] = useState('');
  const [notice_number, setNotice_number] = useState('');
  const [imagesrc, setImagesrc] = useState('');
  const [notice_Upload, setNotice_Upload] = useState('');
  const mailCart = useRef();
  const mailCartFile = useRef();
  const mailCartImage = useRef();

  useEffect(() => {
    
    mailCart.current.addEventListener("click", function () {
      mailCartFile.current.click()
    })

    mailCartFile.current.addEventListener("change", function (event) {
      let fileReader = new FileReader();

      fileReader.onloadend = function () {
        let result = fileReader.result
        setImagesrc(result)
      }

      fileReader.readAsDataURL(event.target.files[0]);

    })

  }, [])

  const { icon2, setIcon2 } = useContext(MyContext);
  const { chaLocate, setChaLocate } = useContext(MyContext);
  const navigate = useNavigate();
  const clickservis = () => {

    if (user_name === "") { setNotice_name('نام خود را وارد کنید') } else { setNotice_name("") }
    if (user_family === "") { setNotice_family("نام خانوادگی خود را وارد نمایید") } else { setNotice_family("") }
    if (!Valid_MeliCode.test(user_code)) { setNotice_code("کد ملی خود را به درستی وارد نمایید") } else { setNotice_code("") }
    if (imagesrc === "") { setNotice_Upload("عکس خواسته شده را آپلود کنید") } else { setNotice_Upload("") }
    if (!Valid_Number.test(user_number)) { setNotice_number("شماره تلفن خود را به درستی وارد نمایید") } else { setNotice_number("") }
    if (user_name != "" && Valid_MeliCode.test(user_code) && Valid_Number.test(user_number) && user_family != "" && imagesrc != "") {
     
      setChaLocate("RealLocation")
      navigate('/registering/3')
    }
    else {
      return;
    }
  }

  return (
    <div className="plana">
      <div className="map-consoles">
        <div className="banner-planreal">
          <span>شخص حقیقی</span>
        </div>
        <div className="list-input" >
          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">نام</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="نام" aria-describedby="defaultFormControlHelp" value={user_name} onChange={e => setUser_name(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {notice_name}
              </div>
            </div>

          </div>

          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">نام خانوادگی</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="نام خانوادگی" aria-describedby="defaultFormControlHelp" value={user_family} onChange={e => setUser_family(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {notice_family}
              </div>
            </div>

          </div>

        </div>
        <div className="list-input" >
          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">کد ملی</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="xxx-xxxxxx-x" aria-describedby="defaultFormControlHelp" value={user_code} onChange={e => setUser_code(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {notice_code}
              </div>
            </div>

          </div>

          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">شماره تماس</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="شماره تماس کاری" aria-describedby="defaultFormControlHelp" value={user_number} onChange={e => setUser_number(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {notice_number}
              </div>
            </div>

          </div>

        </div>
        <div className="list-input" >
          <div className="input-label" >
            <label htmlFor="formFile" className="form-label"> تصویر کارت ملی</label>
            <input type="file" ref={mailCartFile} style={{ display: "none" }} />
            <button type="button" className="btn btn-outline-primary waves-effect wid-94 bord-ght" ref={mailCart} >
              <FaFolderPlus className="ti-xs ti ti-bell pruficon" />آپلود کارت ملی</button>
            <div id="defaultFormControlHelp" className="form-text error-valid error-valid">
              {notice_Upload}
            </div>
          </div>
          <div className="input-label" >
          </div>


        </div>
        <div className="btn-list-input" >
          <button className="btn btn-primary btn-plan" onClick={clickservis} >مرحله بعد</button>
        </div>
      </div>
      <FooterPlan />
    </div>
  );
}

export default PlanA1;