import React, { useEffect, useState } from "react";
import './../DashBoard.css';
import {
    FaBeer, FaShoppingCart, FaHouseUser, FaMoneyCheck, FaMoneyCheckAlt,
    FaProductHunt, FaQuoteLeft, FaHistory, FaShoppingBasket,
    FaFileContract, FaUniversity,
} from 'react-icons/fa';
import { AiFillCar, AiOutlineCar } from "react-icons/ai";
import { FaRegNewspaper, FaTruckFast } from 'react-icons/fa6';
import { MyContext } from "../ContexApi/MyContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import BackDrops from "../Tools/BackDrops";
import SideBarBackDrops from "../Tools/SideBarBackDrop";



const DashSideBar = (props) => {

    const { hrefFunc, setHrefFunc } = useContext(MyContext);
    const { showSideBar , setShowSideBar } = useContext(MyContext);
    const [maliOpen, setMaliOpen] = useState(false);
    const [orderOpen, setOrderOpen] = useState(false);
    const [itemsOpen, setItemsOpen] = useState(false);


    const closeSideBar = () =>{
        setShowSideBar(false)
    }

    const maliHandler = () => {
        setMaliOpen(!maliOpen)
        setOrderOpen(false)
        setItemsOpen(false)

    }
    const orderHandler = () => {
        setOrderOpen(!orderOpen)
        setMaliOpen(false)
        setItemsOpen(false)

    }
    const itemsHandler = () => {
        setItemsOpen(!itemsOpen)
        setMaliOpen(false)
        setOrderOpen(false)
    }


    useEffect(()=>{
        setShowSideBar(false)

    },[])

    return (
        <>
            <SideBarBackDrops showModal={showSideBar} closeModal={closeSideBar} />
            <aside id="layout-menu" className={`layout-menu menu-vertical menu bg-menu-theme ${showSideBar?'tranz':null}`} style={{overflowY : 'auto'}} >
                <div className="app-brand demo" style={{ borderBottom : '1px solid #e1e1e1'}}>
                    <Link to="/dashboard" className="app-brand-link">
                        <span className="app-brand-logo">
                            <AiOutlineCar  color="#131428" size={35} />
                        </span>
                        <span className="app-brand-text demo menu-text fw-bold">فیکسیت</span>

                    </Link>

                    <Link className="layout-menu-toggle menu-link text-large ms-auto">
                        <i className="ti ti-x d-block d-xl-none ti-sm align-middle" onClick={()=>setShowSideBar(false)} />
                    </Link>
                </div>
                <div className="menu-inner-shadow" />
                <ul className="menu-inner py-1">
                    {/* Dashboards */}
                    <li className={`menu-item ${hrefFunc == 'dashboard' ? "active" : null}`}>
                        <Link to="/dashboard" className="menu-link">
                            <FaHouseUser className="menu-icon" size={17} />
                            <div data-i18n="پنل کاربری">پنل کاربری</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                    <li className={`menu-item ${orderOpen ? "open" : null} ${hrefFunc === 'product' ? "open" : null}`} >
                        <span className="menu-link menu-toggle" onClick={orderHandler}>
                            <FaShoppingCart className="menu-icon" size={17} />
                            <div data-i18n="سفارشات">سفارشات</div>
                        </span>
                        <ul className="menu-sub">
                            <li className={`menu-item ${hrefFunc == 'product' ? "active" : null}`}>
                                <Link to="/dashboard/product" className="menu-link">
                                    <div data-i18n="همه سفارشات">همه سفارشات</div>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`menu-item ${hrefFunc == 'price-managed' ? "active" : null}`}>
                        <Link to="/dashboard/price-managed" className="menu-link">
                            <FaMoneyCheck className="menu-icon" size={17} />
                            <div data-i18n="مدیریت قیمت ها">مدیریت قیمت ها</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                    <li className={`menu-item ${itemsOpen ? "open" : null} ${hrefFunc === 'myorders' || hrefFunc === 'allorders' || hrefFunc === 'addorders' ? "open" : null}`} >
                        <span className="menu-link menu-toggle" onClick={itemsHandler}>
                            <FaProductHunt className="menu-icon" size={17} />
                            <div data-i18n="محصولات">محصولات</div>
                        </span>
                        <ul className="menu-sub">
                            <li className={`menu-item ${hrefFunc == 'myorders' ? "active" : null}`}>
                                <Link to="/dashboard/myorders" className="menu-link">
                                    <div data-i18n="محصولات من">محصولات من</div>
                                </Link>
                            </li>
                            <li className={`menu-item ${hrefFunc == 'allorders' ? "active" : null}`}>
                                <Link to="/dashboard/allorders" className="menu-link" >
                                    <div data-i18n="همه محصولات">همه محصولات</div>
                                </Link>
                            </li>
                            <li className={`menu-item ${hrefFunc == 'addorders' ? "active" : null}`}>
                                <Link to="/dashboard/addorders" className="menu-link">
                                    <div data-i18n="افزودن محصول جدید">افزودن محصول جدید</div>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`menu-item ${hrefFunc == 'call-price' ? "active" : null}`}>
                        <Link to="/dashboard/call-price" className="menu-link">
                            <FaMoneyCheckAlt className="menu-icon" size={17} />
                            <div data-i18n="استعلام قیمت ها">استعلام قیمت</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                    <li className={`menu-item ${hrefFunc == 'tickets' ? "active" : null}`}>
                        <Link to="/dashboard/tickets" className="menu-link">
                            <FaQuoteLeft className="menu-icon" size={17} />
                            <div data-i18n="تیکت ها">درخواست پشتیبانی</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                    <li className={`menu-item ${maliOpen ? "open" : null} ${hrefFunc === 'sell-history' ? "open" : null}`}  >
                        <span className="menu-link menu-toggle" onClick={maliHandler}>
                            <FaHistory className="menu-icon" size={17} />
                            <div data-i18n="مالی">مالی</div>
                        </span>
                        <ul className="menu-sub">
                            <li className={`menu-item ${hrefFunc == 'sell-history' ? "active" : null}`}>
                                <Link to="/dashboard/sell-history" className="menu-link">
                                    <div data-i18n="سوابق فروش">سوابق فروش</div>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`menu-item ${hrefFunc == 'holydays' ? "active" : null}`}>
                        <Link to="/dashboard/holydays" className="menu-link">
                            <FaShoppingBasket className="menu-icon" size={17} />
                            <div data-i18n="تعطیلات">تعطیلات</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                    <li className={`menu-item ${hrefFunc == 'Contract' ? "active" : null}`}>
                        <Link to="/dashboard/Contract" className="menu-link">
                            <FaFileContract className="menu-icon" size={17} />
                            <div data-i18n="قرارداد">قرارداد</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li> 
                    <li className={`menu-item ${hrefFunc == 'academy' ? "active" : null}`}>
                        <Link to="/dashboard/academy" className="menu-link">
                            <FaUniversity className="menu-icon" size={17} />
                            <div data-i18n="آموزش">آموزش </div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                    <li className={`menu-item ${hrefFunc == 'notice' ? "active" : null}`}>
                        <Link to="/dashboard/notice" className="menu-link">
                            <FaRegNewspaper className="menu-icon" size={17} />
                            <div data-i18n="اطلاع رسانی">اطلاع رسانی</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">3</div> */}
                        </Link>
                    </li>
                </ul>
            </aside>

        </>
    );
};
export default DashSideBar;
