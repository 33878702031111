import React from 'react'
import { MoonLoader } from 'react-spinners';
import "./Tools.css";

function LoadingBackdrops(props) {
    return (

        props.LoadingBackdrops ?

            <div className='backdrop-loadings'>
                <MoonLoader
                    color="#131428"
                    size={90}
                    speedMultiplier={1.2}
                />
            </div>
            :
            null
    )
}

export default LoadingBackdrops;