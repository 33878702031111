import React from "react";
import './../DashBoard.css';
import DashSideBar from "./../HomeComponent/DashSideBar";
import DashNavBar from "./../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import { useContext, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";



const Notice = () => {

    const [isLoading, setIsLoading] = useState(false);

    const { hrefFunc, setHrefFunc } = useContext(MyContext);


    const navigate = useNavigate()


    useEffect(() => {

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

    }, [])




    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="card mb-4">
                                            <h5 className="card-header filled" >اطلاع رسانی</h5>
                                            <hr style={{ marginTop: '0.1rem', marginBottom: '0.3rem' }} />

                                            {
                                                isLoading ?
                                                    <div className="loader">
                                                        <HashLoader
                                                            size={25}
                                                            color="#2c5d6f"
                                                            cssOverride={{}}
                                                            loading
                                                            speedMultiplier={1.4}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card-body">
                                                        <li className="d-flex mb-2 py-2 px-3 border rounded" style={{ backgroundColor: '#4b4b4b8c' }}>
                                                            <div className="me-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-slash" viewBox="0 0 16 16">
                                                                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                                                    <path d="M14.975 10.025a3.5 3.5 0 1 0-4.95 4.95 3.5 3.5 0 0 0 4.95-4.95m-4.243.707a2.5 2.5 0 0 1 3.147-.318l-3.465 3.465a2.5 2.5 0 0 1 .318-3.147m.39 3.854 3.464-3.465a2.501 2.501 0 0 1-3.465 3.465Z" />
                                                                </svg>

                                                            </div>
                                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                <div className="me-2">
                                                                    <h6 className="mb-1">بسته شده</h6>
                                                                    <small className="d-block" style={{ color: 'black' }}>Item: #FXZ-4567</small>
                                                                </div>
                                                                <div className="user-progress d-flex align-items-center gap-1">
                                                                    <p className="mb-0 fw-medium">$999.29</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex mb-2 py-2 px-3 border rounded" style={{ backgroundColor: '#4b4b4b8c' }}>
                                                            <div className="me-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-slash" viewBox="0 0 16 16">
                                                                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                                                    <path d="M14.975 10.025a3.5 3.5 0 1 0-4.95 4.95 3.5 3.5 0 0 0 4.95-4.95m-4.243.707a2.5 2.5 0 0 1 3.147-.318l-3.465 3.465a2.5 2.5 0 0 1 .318-3.147m.39 3.854 3.464-3.465a2.501 2.501 0 0 1-3.465 3.465Z" />
                                                                </svg>

                                                            </div>
                                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                <div className="me-2">
                                                                    <h6 className="mb-1">بسته شده</h6>
                                                                    <small className="d-block" style={{ color: 'black' }}>Item: #FXZ-4567</small>
                                                                </div>
                                                                <div className="user-progress d-flex align-items-center gap-1">
                                                                    <p className="mb-0 fw-medium">$999.29</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </div>
                                            }

                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/* <DashFooter /> */}
                            <div className="content-backdrop fade" />
                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default Notice;
