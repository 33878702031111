import './App.css';
import Registering from './component/Registering';
import { Route, Routes } from 'react-router-dom';
import Plans from './component/Register/Plans';
import Services from './component/Register/services';
import { MyContext } from './component/ContexApi/MyContext';
import React, { useState } from 'react';
import Equal from './component/Register/Equal';
import DashBoard from './component/DashBoard';
import SellerServices from './component/Register/SellerServices';
import StateProvider from './Infrastructure/state/StateProvider';
import Locate from './component/Register/locateMain';
import AddOrders from "./component/SideBarComponents/Orders/AddOrders";
import AllOrders from "./component/SideBarComponents/Orders/AllOrders";
import MyOrders from "./component/SideBarComponents/Orders/MyOrders";
import Product from './component/SideBarComponents/Product';
import PriceManaged from "./component/SideBarComponents/PriceManaged"
import HolyDays from "./component/SideBarComponents/HolyDays";
import CallPrices from "./component/SideBarComponents/CallPrices";
import SellHistory from "./component/SideBarComponents/SellHistory";
import Tickets from "./component/SideBarComponents/Tickets";
import Academy from "./component/SideBarComponents/Academy";
import Notice from './component/SideBarComponents/Notification';
import Welcome from './component/Welcome';
import LastPage from './component/Register/LastPage/LastPage';


// rgb(7 0 78 / 70%)

function App() {

  const [equalPlan, setEqualPlan] = useState('');
  const [hrefFunc, setHrefFunc] = useState('');
  const [icons, setIcons] = useState();
  const [chaLocate, setChaLocate] = useState('');
  const [showText, setShowText] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);


  return (
    <StateProvider>
      <div className="App">
        <div className='app-backdrop'></div>

        <MyContext.Provider value={{
          equalPlan, setEqualPlan,
          hrefFunc, setHrefFunc, showText, setShowText, showSideBar, setShowSideBar,
          icons, setIcons, chaLocate, setChaLocate
        }} >
          <Routes>
            <Route path='/' element={<Welcome />} />
            <Route path='/registering' element={<Registering />} />
            <Route path='/registering/1' element={<Plans />} />
            <Route path='/registering/2' element={<Equal />} />
            <Route path='/registering/3' element={<Locate />} />
            <Route path='/registering/5' element={<Services />} />
            <Route path='/registering/4' element={<SellerServices />} />
            <Route path='/registering/7' element={<LastPage />} />
            <Route path='/dashboard' element={<DashBoard />} />
            <Route path='/dashboard/myorders' element={<MyOrders />} />
            <Route path='/dashboard/allorders' element={<AllOrders />} />
            <Route path='/dashboard/addorders' element={<AddOrders />} />
            <Route path='/dashboard/product' element={<Product />} />
            <Route path='/dashboard/price-managed' element={<PriceManaged />} />
            <Route path='/dashboard/tickets' element={<Tickets />} />
            <Route path='/dashboard/sell-history' element={<SellHistory />} />
            <Route path='/dashboard/call-price' element={<CallPrices />} />
            <Route path='/dashboard/holydays' element={<HolyDays />} />
            <Route path='/dashboard/notice' element={<Notice />} />
            <Route path='/dashboard/academy' element={<Academy />} />
          </Routes>
        </MyContext.Provider>



      </div>
    </StateProvider>
  );
}

export default App;
