import React, { useEffect } from "react";
import './Welcomme.css';
import { FaCity } from "react-icons/fa6";
import ImageMain from "./Register/images/logo-color.png";
import { Link, useNavigate } from "react-router-dom";
import logo from './Register/images/FixIt_logo.png';
import {
     FaMoneyCheckAlt, FaTruckMoving, FaSwatchbook,
    FaUserClock, FaStreetView, FaHeadset, FaMale, FaPhoneAlt, FaEnvelope,
    FaMapMarkerAlt, FaGlobe, FaWhatsapp, FaInstagram, FaLinkedin
} from "react-icons/fa";
import VerticalTimeLine from "./HorizontalTimeLine/VerticalTimeLine";




function Welcome(props) {

    const navigate = useNavigate();

    useEffect(() => {

    }, []);


    const reJuForward = () => {
        navigate('/registering')
    }

    return (

        <div className="welcome-main">
            {/* header */}
            <div className="header-welcome">
                <div className="welcom-icon">
                    <img alt="" src={logo} className="icon-headwel" />
                </div>
                <div className="welcome-button">
                    <span>قبلا ثبت نام کرده اید ؟</span>
                    <Link to='/dashboard' className="biter-link" >
                        <button className="biter-head">
                ورود به داشبورد
                        </button>
                    </Link>
                </div>

            </div>
            {/* header */}
            <div className="welcome-title" >
                {/* <div className="fixing-image"> */}
                <img alt="" className="welcome-img" src={ImageMain} />
                {/* </div> */}
                <span className="titlewelcom-span">
                    به راحتی در فیکسیت فروشنده شوید
                </span>
                <div className="titlewelcom-main">  <button className="titlewelcom-button" onClick={reJuForward} >ورود به پنل</button> </div>
            </div>
            <div className="why-us" id="whyUs">
                <div className="head-why">
                    <div className="matn-khati">
                        <span>
                            مزایای فروش در فیکسیت
                        </span>
                    </div>
                    <div className="khati"></div>
                </div>
                <div className="why-row">
                    <div className='why-culomn'>
                        <div className="inner-whyicon">
                            <FaMoneyCheckAlt size={30} />
                        </div>
                        <div className="inner-whytitle">
                            <h5 style={{ marginBottom: 0, fontSize: 16 , fontWeight : "bold" }}>ثبت‌ نام سریع</h5>
                        </div>
                        <div className="inner-whykhati"></div>
                        <div className="inner-whytext">
                            <span>
                                بزرگترین بازار تخصصی لوازم یدکی خودرو
                            </span>
                        </div>
                    </div>
                    <div className="why-culomn">
                        <div className="inner-whyicon">
                            <FaTruckMoving size={30} />
                        </div>
                        <div className="inner-whytitle">
                            <h5 style={{ marginBottom: 0, fontSize: 16 }}>	پنل کاربری راحت</h5>
                        </div>
                        <div className="inner-whykhati"></div>
                        <div className="inner-whytext">
                            <span>
                                پس از ثبت‌نام در پنل آموزش کار با آن در اختیار شما قرار خواهد گرفت
                            </span>
                        </div>
                    </div>
                    <div className="why-culomn">
                        <div className="inner-whyicon">
                            <FaStreetView size={30} />
                        </div>
                        <div className="inner-whytitle">
                            <h5 style={{ marginBottom: 0, fontSize: 16 }}>گزارش مالی شفاف و تسویه روزانه </h5>
                        </div>
                        <div className="inner-whykhati"></div>
                        <div className="inner-whytext">
                            <span>
                                تسویه پس از دریافت کالا توسط مشتری انجام خواهد شد
                            </span>
                        </div>

                    </div>
                    <div className='why-culomn' >
                        <div className="inner-whyicon">
                            <FaUserClock size={30} />
                        </div>
                        <div className="inner-whytitle">
                            <h5 style={{ marginBottom: 0, fontSize: 16 }}>بیش از 3 میلیون بازدید سالانه</h5>
                        </div>
                        <div className="inner-whykhati"></div>
                        <div className="inner-whytext">
                            <span>
                                کالای شما در بازار گسترده لوازم یدکی نمایش داده می‌شود
                            </span>
                        </div>
                    </div>
                    <div className='why-culomn' >
                        <div className="inner-whyicon">
                            <FaSwatchbook size={30} />
                        </div>
                        <div className="inner-whytitle">
                            <h5 style={{ marginBottom: 0, fontSize: 16 }}>	امکان ارسال توسط فروشنده</h5>
                        </div>
                        <div className="inner-whykhati"></div>
                        <div className="inner-whytext">
                            <span>
                                می‌توانید از هر جای ایران، بسته‌بندی و ارسال کالا را خودتان انجام دهید
                            </span>
                        </div>


                    </div>
                    <div className='why-culomn' >
                        <div className="inner-whyicon">
                            <FaHeadset size={30} />
                        </div>
                        <div className="inner-whytitle">
                            <h5 style={{ marginBottom: 0, fontSize: 16 }}>بازار تخصصی لوازم یدکی </h5>
                        </div>
                        <div className="inner-whykhati"></div>
                        <div className="inner-whytext">
                            <span>
                                بزرگترین بازار تخصصی لوازم یدکی خودرو
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-work " >
                <div className="how-head" >
                    <div className="matn-khati">
                        <span>
                            فرآیند شروع به کار
                        </span>
                    </div>
                    <div className="khati"></div>
                </div>
                <div className="how-row" >

                    <VerticalTimeLine />

                    <div className="card-body pb-0 dis-vertical">
                        <ul className="timeline pt-3">
                            <li className="timeline-item pb-4 timeline-item-primary border-left-dashed ">
                                <span className="timeline-indicator-advanced timeline-indicator-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#48C9B0" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>

                                </span>
                                <div className="timeline-event pb-3">
                                    <div className="timeline-header mb-sm-0 mb-3">
                                        <h6 className="" style={{ fontWeight: "bolder" }}>ثبت نام در پنل</h6>

                                    </div>
                                    <p style={{ fontSize: 12 }}>ثبت اطلاعات حقیقی یا حقوقی و انتظار برای تایید هویت</p>

                                </div>
                            </li>
                            <li className="timeline-item pb-4 timeline-item-success border-left-dashed">
                                <span className="timeline-indicator-advanced timeline-indicator-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#5499C7" className="bi bi-camera-video" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                                    </svg>

                                </span>
                                <div className="timeline-event pb-3">
                                    <div className="timeline-header mb-sm-0 mb-3">
                                        <h6 className="" style={{ fontWeight: "bolder" }}>آموزش نحوه کار با پنل</h6>

                                    </div>
                                    <p style={{ fontSize: 12 }} >تماشای آموزش های مربوط به هر بخش پنل برای استفاده راحت تر</p>

                                </div>
                            </li>
                            <li className="timeline-item pb-4 timeline-item-danger border-left-dashed">
                                <span className="timeline-indicator-advanced timeline-indicator-danger">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#F4D03Fs" className="bi bi-bag-plus" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5" />
                                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                                    </svg>

                                </span>
                                <div className="timeline-event pb-3">
                                    <div className="timeline-header mb-sm-0 mb-3">
                                        <h6 className="" style={{ fontWeight: "bolder" }}>ثبت محصولات و ایجاد فروشگاه</h6>

                                    </div>
                                    <p style={{ fontSize: 12 }}>ثبت محصولاتی که به مشتریان خود ارائه می دهید، به همراه قیمت</p>

                                </div>
                            </li>
                            <li className="timeline-item pb-4 timeline-item-info border-left-dashed">
                                <span className="timeline-indicator-advanced timeline-indicator-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#5D6D7E" className="bi bi-save2" viewBox="0 0 16 16">
                                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z" />
                                    </svg>

                                </span>
                                <div className="timeline-event pb-3">
                                    <div className="timeline-header mb-sm-0 mb-3">
                                        <h6 className="" style={{ fontWeight: "bolder", marginBottom: 10 }}>آغاز فعالیت در فیکسیت</h6>

                                    </div>
                                    <p style={{ fontSize: 12 }}>شروع فروش محصولات خود در فروشگاه اینترنتی فیکسیت</p>

                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div className='re-ju'>
                <div className="reju-head">
                    <div className="matn-khati">
                        <span>
                            مدارک مورد نیاز
                        </span>
                    </div>
                    <div className="khati"></div>
                </div>
                <div className="reju-what"  >
                    <div className="reju-item " >
                        <div className="titr-icon">
                            <div><FaMale size={40} /> </div>
                            <div>
                                <div className="in-pan" onClick={reJuForward}>فرد حقیقی</div>
                            </div>
                        </div>
                        <div className="under-matn">  فروشنده حقیقی، فارغ از هرگونه شرکتی، به شکل مستقل کسب و کار خود را پیش می‌برد.
                        </div>

                    </div>
                    <div className="reju-line" ></div>
                    <div className="reju-item" >
                        <div className="titr-icon">
                            <div><FaCity size={40} /></div>
                            <div>
                                <div className="in-pan" onClick={reJuForward}>فرد حقوقی</div>
                            </div>
                        </div>
                        <div className="under-matn">  فروشنده حقوقی مجموعه‌ها و شرکت‌هایی هستند که به طور قانونی مشغول به فعالیت در کسب و کار خود می‌باشند.
                        </div>

                    </div>

                </div>
            </div>
            <div className="question">
                <div>
                </div>
                <div className="col-md mb-4 mb-md-2" style={{ width: "98%" }}>
                    <small className='fw-medium' style={{ fontSize: 18 }}>سوالات متداول</small>
                    <div className="accordion mt-3" id="accordionExample">
                        <div className="card accordion-item" >
                            <h2 className="accordion-header" id="headingOne">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="false" aria-controls="accordionOne">
                                    شرایط ثبت نام فروشنده در فیکسیت چیست؟
                                </button>
                            </h2>
                            <div id="accordionOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    تمامی اشخاص حقیقی و حقوقی که تولیدکننده، بازرگان یا دارنده کالا باشند می‌توانند به عنوان فروشنده در سایت فیکسیت ثبت‌نام نمایند و پس از احراز هویت و دریافت پنل فروشندگان کالای خود را در معرض فروش قرار دهند. لازم به ذکر است کارکرد این پنل بسیار ساده است و نیازی به تخصص خاصی ندارد.
                                </div>
                            </div>
                        </div>
                        <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                                    برای ثبت‌نام چه مدارکی نیاز است؟
                                </button>
                            </h2>
                            <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    اگر فرد حقیقی هستید عکس کارت ملی و جواز کسب؛
                                    اگر حقوقی هستید مدارک شرکت با کارت ملی نماینده یا مدیر عامل.

                                </div>
                            </div>
                        </div>
                        <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">
                                    در مورد قرارداد سوال دارم!!
                                </button>
                            </h2>
                            <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    بعد از تایید ثبت‌نام یک دوره آموزش در اختیار شما قرار می‌گیرد، در صورت مدیریت صحیح پنل همکاری بسیار فروش در فیکسیت ساده خواهد بود. لازم به ذکر است تا قبل از آغاز اولین فروش هیچگونه تعهد قانونی شامل حال شما نمی‌شود.
                                </div>
                            </div>
                        </div>
                        <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFour" aria-expanded="false" aria-controls="accordionFour">
                                    برای ارسال سفارش چه کاری باید انجام دهم؟
                                </button>
                            </h2>
                            <div id="accordionFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    پس از ثبت سفارش توسط مشتری، برای شما پیامکی حاوی مراجعه به پنل کاربر ارسال خواهد شد که می‌توانید سفارش‌های ثبت شده را مشاهده و انجام دهید.
                                </div>
                            </div>
                        </div>
                        <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFine" aria-expanded="false" aria-controls="accordionFine">
                                    آیا وارد کردن کد رهگیری مالیاتی اجبار است ؟
                                </button>
                            </h2>
                            <div id="accordionFine" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    برای اشخاص حقوقی، بله!
                                </div>
                            </div>
                        </div>
                        <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionSix" aria-expanded="false" aria-controls="accordionSix">
                                    تسویه حساب فیکسیت با تامین‌کنندگان چگونه است؟
                                </button>
                            </h2>
                            <div id="accordionSix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    تمامی کالاهای فروخته شده ۱ روز کاری پس از تحویل کالا توسط مامور ارسال به مشتری تسویه خواهد شد.
                                </div>
                            </div>
                        </div>
                        <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionSeven" aria-expanded="false" aria-controls="accordionSeven">
                                    نام فروشگاه یا فروشنده چیست؟

                                </button>
                            </h2>
                            <div id="accordionSeven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                                <div className="accordion-body">
                                    نام تجاری به معنای نامی است که فروشنده برای پنل فروش خود انتخاب می‌نماید و می‌بایست نامی به غیر از نام اصلی فروشنده یا مغازه وی باشد.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="footer-welcome">
                <div className="footer-welrow">
                    <div className="footer-fcolumn">
                        <div className="items-fcolumn" >
                            <div className="icon-fcolumn">
                                <FaPhoneAlt className="airIcon" size={20} color="#061c2f" />
                            </div>
                            <div className="span-fcolumn">
                                <span>شماره های ارتباطی</span>
                            </div>
                        </div>
                        <div className="items-fcolumn1">
                            <span>09966260085</span>
                        </div>
                    </div>
                    <div className="footer-fcolumn">
                        <div className="items-fcolumn">
                            <div className="icon-fcolumn">
                                <FaEnvelope className="airIcon" size={20} color="#061c2f" />
                            </div>
                            <div className="span-fcolumn">
                                <span>ایمیل های ارتباطی</span>
                            </div>
                        </div>
                        <div className="items-fcolumn1">
                            <span>amirreza.khs@gmail.com</span>
                        </div>
                    </div>
                    <div className="footer-fcolumn marg0bottom">
                        <div className="items-fcolumn">
                            <div className="icon-fcolumn">
                                <FaMapMarkerAlt className="airIcon" size={20} color="#061c2f" />
                            </div>
                            <div className="span-fcolumn">
                                <span>آدرس شرکت</span>
                            </div>
                        </div>
                        <div className="items-fcolumn1">
                            <span>آدرس : تهران، خیابان جمهوری</span>
                        </div>
                    </div>
                    <div className="footer-fcolumn">
                        <div className="items-fcolumn">
                            <div className="icon-fcolumn">
                                <FaGlobe className="airIcon" size={20} color="#061c2f" />
                            </div>
                            <div className="span-fcolumn">
                                <span>ما را در شبکه های اجتماعی دنبال کنید</span>
                            </div>
                        </div>
                        <div className="items-fcolumn11">
                            <div className="faaa-marg">
                                <FaWhatsapp className="airIcon" size={20} color="#061c2f" />
                            </div>
                            <div className="faaa-marg">
                                <FaInstagram className="airIcon" size={20} color="#061c2f" />
                            </div>
                            <div className="faaa-marg">
                                <FaLinkedin className="airIcon" size={20} color="#061c2f" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-welrow1">
                    <div className="footer-listco">
                        <label className="label-listco">
                            شماره های ارتباطی
                        </label>
                        <ul className="listco-ol">
                            <li className="listco-li">
                                <span>تماس با ما</span>
                            </li>
                            <li className="listco-li">
                                <span>درباره فیکسیت</span>
                            </li>
                            <li className="listco-li">
                                <span>قوانین و مقررات</span>
                            </li>
                            <li className="listco-li">
                                <span>شرایط بازگشت کالا</span>
                            </li>
                            <li className="listco-li">
                                <span>وبلاگ</span>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-listco">
                        <label className="label-listco">
                            لینک های سریع
                        </label>
                        <ul className="listco-ol">
                            <li className="listco-li">
                                <span>فرصت های شغلی</span>
                            </li>
                            <li className="listco-li">
                                <span>فروشنده شو</span>
                            </li>
                            <li className="listco-li">
                                <span>خدمات خودرویی</span>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-lastpic">
                        <div className="lastpic-img rensz">
                            <img  src="https://mashinno.com/assets/app/e-namad.webp" alt="" className="namad" />
                        </div>
                        <div className="lastpic-img">
                            <img  src="https://mashinno.com/assets/app/kasbokar.jpg" alt="" className="namad" />
                        </div>

                    </div>
                </div>
            </div>







        </div>





    );
}

export default Welcome;
