import React, { useState } from "react";
import './../DashBoard.css';
import DashSideBar from "./../HomeComponent/DashSideBar";
import DashNavBar from "./../HomeComponent/DashNavBar";
import { MyContext } from "../ContexApi/MyContext";
import { useContext, useEffect } from "react";
import { FaBell } from "react-icons/fa6";
import TicketModal from "../Tools/TicketModal";
import { FaRegBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Tickets = () => {

    const [isLoading, setLoading] = useState(false);

    const { hrefFunc, setHrefFunc } = useContext(MyContext);

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate()

    const [changeBell, setChangeBell] = useState("");

    const allBellHandler = () => {
        setChangeBell('همه')
    }
    const lowBellHandler = () => {
        setChangeBell('کم')

    }
    const avrBellHandler = () => {
        setChangeBell('متوسط')

    }
    const highBellHandler = () => {
        setChangeBell('زیاد')

    }
    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }


    // const isLoggedIn = () => {
    //     try {
    //       if (state.auth != null) {
    //         if (state.auth.authorisation != null || state.auth.authorisation != undefined) {
    //           return true
    //         }
    
    //       }
    //     } catch (e) {
    //       return false;
    //     }
    //   }


    useEffect(() => {

        const getAddress = window.location.href.split('/');
        const lengthAddress = getAddress.length;
        const finaleAddress = getAddress[lengthAddress - 1]
        setHrefFunc(finaleAddress)

    }, [])


    return (
        <>

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <DashSideBar />

                    <div className="layout-page">
                        <DashNavBar />
                        <div className="content-wrapper flex-align">
                            {/* Content */}
                            <div className="demo-inline-spacing" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "90%" }}>
                                <button type="button" className="btn btn-danger waves-effect waves-light">باز نشده</button>
                                <button type="button" className="btn btn-warning waves-effect waves-light">در حال بررسی</button>
                                <button type="button" className="btn btn-success waves-effect waves-light">پاسخ داده </button>
                                <button type="button" className="btn btn-dark waves-effect waves-light">بسته شده</button>
                            </div>
                            <div className="container-xxl flex-grow-1 container-p-y" >
                                <div className="col-12">
                                    <div className="card mb-4">
                                        <div className="firstava">
                                            <div className="headava">
                                                <div className="avatar avatar-lg me-2 avatar-online" >
                                                    <img src="../../assets/img/avatars/1.png" alt="Avatar" className="rounded-circle" />
                                                </div>
                                                <label>امیررضا خصالی</label>
                                            </div>
                                            <div className="headava">
                                                <button type="button" className="btn btn-success waves-effect waves-light" onClick={openModal}>تیکت جدید</button>
                                            </div>
                                        </div>
                                        <div className="sermaintain wid-85">
                                            <div className="input-group input-group-merge">
                                                <span className="input-group-text" id="basic-addon-search31"><i className="ti ti-search" /></span>
                                                <input type="text" className="form-control" placeholder="جست و جو در تیکت ها" aria-label="Search..." aria-describedby="basic-addon-search31" />
                                            </div>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="card-body">
                                            <div className="listIcons">
                                                <ul className="divList">
                                                    {
                                                        changeBell === "همه" ?
                                                            <li className="takIcon"><FaRegBell size={20} />همه</li> : <li className="takIcon"><FaBell size={20} onClick={allBellHandler} />همه</li>
                                                    }
                                                    {
                                                        changeBell === "کم" ?
                                                            <li className="takIcon"><FaRegBell size={20} />کم</li> : <li className="takIcon"><FaBell size={20} onClick={lowBellHandler} />کم</li>
                                                    }
                                                    {
                                                        changeBell === "متوسط" ?
                                                            <li className="takIcon"><FaRegBell size={20} />متوسط</li> : <li className="takIcon"><FaBell size={20} onClick={avrBellHandler} />متوسط</li>
                                                    }
                                                    {
                                                        changeBell === "زیاد" ?
                                                            <li className="takIcon"><FaRegBell size={20} />زیاد</li> : <li className="takIcon"><FaBell size={20} onClick={highBellHandler} />زیاد</li>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="mb-3">
                                                <select className="form-select" id="exampleFormControlSelect1" defaultValue={0}>
                                                    <option value={0} >همه موارد (به جز بسته شده)</option>
                                                    <option value={1}>باز نشده</option>
                                                    <option value={2}>در حال بررسی</option>
                                                    <option value={3}>پاسخ داده شده</option>
                                                    <option value={3}>بسته شده</option>
                                                </select>
                                            </div>
                                            <div className="col-12" >

                                                <div className="h-100">

                                                    <div className="card-body p-2">
                                                        <ul className="p-0 m-0">
                                                            <li className="d-flex mb-2 py-2 px-3 border rounded" style={{ backgroundColor: '#ea545587' }}>
                                                                <div className="me-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                                    </svg>


                                                                </div>
                                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                    <div className="me-2">
                                                                        <h6 className="mb-1">بسته شده</h6>
                                                                        <small className="d-block" style={{ color: 'black' }}>Item: #FXZ-4567</small>
                                                                    </div>
                                                                    <div className="user-progress d-flex align-items-center gap-1">
                                                                        <p className="mb-0 fw-medium">$999.29</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex mb-2 py-2 px-3 border rounded" style={{ backgroundColor: '#ff9f43a1' }}>
                                                                <div className="me-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-paper" viewBox="0 0 16 16">
                                                                        <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267zm13 .566v5.734l-4.778-2.867zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083zM1 13.116V7.383l4.778 2.867L1 13.117Z" />
                                                                    </svg>

                                                                </div>
                                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                    <div className="me-2">
                                                                        <h6 className="mb-1">در حال بررسی</h6>
                                                                        <small className="d-block" style={{ color: 'black' }}>Item: #FXZ-4567</small>
                                                                    </div>
                                                                    <div className="user-progress d-flex align-items-center gap-1">
                                                                        <p className="mb-0 fw-medium">$999.29</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex mb-2 py-2 px-3 border rounded" style={{ backgroundColor: 'rgb(44 30 190 / 49%)' }}>
                                                                <div className="me-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-check" viewBox="0 0 16 16">
                                                                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                                                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686" />
                                                                    </svg>

                                                                </div>
                                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                    <div className="me-2">
                                                                        <h6 className="mb-1">پاسخ داده شده</h6>
                                                                        <small className="d-block" style={{ color: 'black' }}>Item: #FXZ-4567</small>
                                                                    </div>
                                                                    <div className="user-progress d-flex align-items-center gap-1">
                                                                        <p className="mb-0 fw-medium">$999.29</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex mb-2 py-2 px-3 border rounded" style={{ backgroundColor: '#4b4b4b8c' }}>
                                                                <div className="me-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-slash" viewBox="0 0 16 16">
                                                                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                                                        <path d="M14.975 10.025a3.5 3.5 0 1 0-4.95 4.95 3.5 3.5 0 0 0 4.95-4.95m-4.243.707a2.5 2.5 0 0 1 3.147-.318l-3.465 3.465a2.5 2.5 0 0 1 .318-3.147m.39 3.854 3.464-3.465a2.501 2.501 0 0 1-3.465 3.465Z" />
                                                                    </svg>

                                                                </div>
                                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                    <div className="me-2">
                                                                        <h6 className="mb-1">بسته شده</h6>
                                                                        <small className="d-block" style={{ color: 'black' }}>Item: #FXZ-4567</small>
                                                                    </div>
                                                                    <div className="user-progress d-flex align-items-center gap-1">
                                                                        <p className="mb-0 fw-medium">$999.29</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                showModal ? <TicketModal closeModal={closeModal} showModal={showModal} /> : null
                            }

                        </div>
                    </div>

                </div>
            </div>







        </>
    );
};
export default Tickets;
