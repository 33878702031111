import React from 'react';
import { IoIosClose } from 'react-icons/io';
import BackDrops from './BackDrops';
import { useEffect } from 'react';
import Swal from 'sweetalert2';


function ProductModal(props) {



    useEffect(() => {

        // if (!state.isLoaded) {
        //     return;
        // }



        console.log(props.information)
    }, [])



    const messagesBox = () => {
        Swal.fire({
            title: "لیست توضیحات محصول",
            html : "<h4>پیامی ثبت نگردیده است</h4>",
            input: "textarea",
            inputPlaceholder : "پیامتان را وارد نمایید",
            inputLabel : "اگر پیامی دارید می توانید در کادر زیر وارد کرده و ثبت کنید" ,
            showCancelButton: true,
            confirmButtonText: "تایید پیام",
            showLoaderOnConfirm: true,
            cancelButtonText: "انصراف",
            inputValue: (value) => { console.log(value) },
            preConfirm: () => {
                // console.log(inputValue)

            },

        });
    }

    const sisuationBox = async () => {

        const { value: fruit } = await Swal.fire({
            title: "تغییر وضعیت کالا",
            html : "<div class='badge2'><div>وضعیت کنونی کالا</div><div  class='inner-badge'>ارسال شده</div></div>",
            input: "select",
            inputLabel : "می توانید وضعیت کالای خود را تغییر دهید" ,
            inputOptions: {

                sent: "تحویل شده",
                delivered: "پرداخت شده",
                ready_to_send: "آماده ارسال",
                paid: "ارسال شده",
                sent_to_store: "ارسال به انبار",
            },
            inputPlaceholder: "وضعیت کالا",
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value === "oranges") {
                        resolve();
                    } else {
                        resolve("You need to select oranges :)");
                    }
                });
            }
        });
        if (fruit) {
            Swal.fire(`You selected: ${fruit}`);
        }

    }


    return (
        <>


            <BackDrops closeModal={props.closeModal} showModal={props.showModal} />
            <div className='product-modal'>

                <div className='ho-modal'>
                    <div className='header-ticket'>
                        <div className='tickethead-prop ptop-text' >
                            <span>جزئیات کامل سفارش</span>
                        </div>
                        <div className='tickethead-prop ptop-icon'>
                            <IoIosClose size={30} onClick={props.closeModal} />
                        </div>
                    </div>
                </div>
                <div className='pro-modal'>

                    <div className='div-ndar'>
                        <span>وضعیت :</span>
                        {
                            props.information.statusText === "پرداخت شده" ?
                                <span className='badge curse-point' style={{ backgroundColor: "red" }}>{props.information.statusText}</span> :
                                props.information.statusText === "تحویل شده" ?
                                    <span className='badge curse-point' style={{ backgroundColor: "green" }}>{props.information.statusText}</span> :
                                    props.information.statusText === "آماده ارسال" ?
                                        <span className='badge curse-point' style={{ backgroundColor: "#e1e1e1" }}>{props.information.statusText}</span> :
                                        props.information.statusText === "ارسال شده" ?
                                            <span className='badge curse-point' style={{ backgroundColor: "orange" }}>{props.information.statusText}</span> :
                                            props.information.statusText === "ارسال به انبار" ?
                                                <span className='badge curse-point' style={{ backgroundColor: "gold" }}>{props.information.statusText}</span> :
                                                <span className='badge curse-point' style={{ backgroundColor: "lightgreen" }}>{props.information.statusText}</span>}
                    </div>
                    <div className='div-ndar finale-productmodal-650 mar-ri-1'>
                        <span>تاریخ ثبت سفارش :</span>
                        <span>{props.information.dateText}</span>
                    </div>
                    <div className='div-ndar finale-productmodal-650'>
                        <span> کد تخفیف :</span>
                        <span>{props.information.lines[0].quantity}</span>
                    </div>
                    <div className='div-ndar finale-productmodal-650 mar-ri-1'>
                        <span> :</span>
                        <span></span>
                    </div>
                    <div className='div-ndar finale-productmodal-650'>
                        <span> نوع ارسال :</span>
                        <span></span>
                    </div>
                    <div className='div-ndar finale-productmodal-650 mar-ri-1'>
                        <span> شماره شاسی :</span>
                        <span></span>
                    </div>
                    <div className='div-ndar finale-productmodal-650'>
                        <span>مبلغ فاکتور :</span>
                        <span>{props.information.amount}</span>
                    </div>
                    <div className='div-ndar finale-productmodal-650 mar-ri-1'>
                        <span>تاریخ پرداخت :</span>
                        <span>{props.information.date}</span>
                    </div>
                    <div className='div-ndar finale-productmodal-650'>
                        <span>زمان دریافت :</span>
                        <span></span>
                    </div>
                </div>
                <hr style={{ marginBottom: "0.2rem", marginTop: "0.2rem", width: "100%" }} />
                <div className='pro-modalbtn'>








                    {

                        props.information.lines.map((id, index) =>
                            <div className="table-responsive" style={{ borderWidth: 1, borderColor: '#919191', borderStyle: 'solid', borderRadius: 4, marginBottom: "0.5rem" }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className=''>ردیف</th>
                                            <th className='' style={{ width: "10%" }}>عکس</th>
                                            <th className=''>عنوان</th>
                                            <th className='mob-res-hide'>برند</th>
                                            <th className='mob-res-hide'>قیمت</th>
                                            <th className='mob-res-hide'><span className='badge curse-point' style={{ backgroundColor: '#131428' }} onClick={messagesBox}>پیام ها</span></th>
                                            <th className='mob-res-hide'>کد کالا</th>
                                            <th className=''>تعداد</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td style={{}}>
                                                <img src={id.image} style={{ height: "auto", width: "30%", minWidth: 100 }} className='imgresizing' />
                                            </td>
                                            <td style={{ fontSize: 12 }}>
                                                {id.name}
                                            </td>
                                            <td className='mob-res-hide'>
                                                test
                                            </td>
                                            <td className='mob-res-hide'>
                                                {props.information.amount}
                                            </td>
                                            <td className='mob-res-hide'>
                                                <span className='badge curse-point' style={{ backgroundColor: '#131428' }} onClick={sisuationBox} >وضعیت</span>
                                            </td>
                                            <td className='mob-res-hide'>
                                                {id.product_id}

                                            </td>

                                            <td className=''>
                                                {id.quantity}

                                            </td>
                                        </tr>
                                        <tr className='mob-all-hide'>
                                            <th className=''>برند</th>
                                            <td className=''>قیمت</td>
                                            <td className='' ><span className='badge curse-point' style={{ backgroundColor: '#131428' }} onClick={messagesBox}>پیام ها</span></td>
                                            <td className=''>کد کالا</td>
                                        </tr>
                                        <tr className='mob-all-hide'>

                                            <td className=''>
                                                test
                                            </td>
                                            <td className=''>
                                            </td>
                                            <td>
                                                <span className='badge curse-point' style={{ backgroundColor: '#131428' }} onClick={sisuationBox}>وضعیت</span>
                                            </td>
                                            <td className=''>
                                                {id.product_id}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        )

                    }













                </div>
            </div>

        </>
    )
}

export default ProductModal;