import React from 'react'
import './Tools.css';



function SideBarBackDrops(props) {
  
    return (

    // props.showModal ? 
    <div className={`sidebar-backdrop ${props.showModal ? "opacity-backdrop" : null }`} onClick={props.closeModal} > </div> 
    // : null

  )
}

export default SideBarBackDrops ;