import React from 'react';
import { IoIosClose } from 'react-icons/io';
import BackDrops from './BackDrops';


function TicketModal(props) {




    return (
        <>

            <BackDrops closeModal={props.closeModal} showModal={props.showModal} />
            <div className='holy-modal'>

                <div className='ho-modal'>
                    <div className='header-ticket'>
                        <div className='tickethead-prop ptop-text'>
                            <span>ارسال تیکت جدید</span>
                        </div>
                        <div className='tickethead-prop ptop-icon'>
                            <IoIosClose size={30} onClick={props.closeModal} />
                        </div>
                    </div>
                </div>
                <div className='ho-modal'>
                    <div className="wid">
                        <label htmlFor="defaultSelect" className="form-label">دپارتمان ها</label>
                        <select id="defaultSelect" className="form-select">
                            <option>فروش</option>
                            <option value="حسابداری">حسابداری</option>
                            <option value="مارکتینگ">مارکتینگ</option>
                            <option value="خرید">خرید</option>
                            <option value="شکایات">شکایات</option>
                            <option value="واحد ارسال">واحد ارسال</option>
                            <option value="ادمین های سایت">ادمین های سایت</option>
                            <option value="مدیریت">مدیریت</option>
                            <option value="پیگیری">پیگیری</option>
                            <option value="انجام شده">انجام شده</option>


                        </select>
                    </div>
                </div>
                <div className='ho-modal'>
                    <div className='wid1'>
                        <label htmlFor="defaultFormControlInput" className="form-label">موضوع : تعداد کاراکتر مجاز (30)</label>

                        <input type="text" className="form-control" id="defaultFormControlInput" placeholder="John Doe" aria-describedby="defaultFormControlHelp" />

                    </div>
                    <div className="wid2">
                        <label htmlFor="defaultSelect" className="form-label">اولویت</label>
                        <select id="defaultSelect" className="form-select">
                            <option value={3} selected >کم</option>
                            <option value={1}>متوسط</option>
                            <option value={2}>زیاد</option>
                        </select>
                    </div>
                </div>
                <div className='ho-modal heightbtn'>
                    <div className='widareas'>
                        <label className="form-label">متن اتیکت</label>
                        <textarea className="form-control" rows={3} defaultValue={""} />
                    </div>

                </div>
                <div className='ho-modal widbtn heightarea'>
                    <button className='btn btn-primary'>ارسال تیکت</button>
                </div>

            </div>
        </>
    )
}

export default TicketModal;