import React, { useContext, useEffect } from "react";
import './Plans.css';
import { MyContext } from "../ContexApi/MyContext";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../SideBar/SideBar";
import HeaderRegister from "../SideBar/Header";
import FooterPlan from "../SideBar/FooterPlan";
import realiLogo from "./images/8474.JPG";
import judgeLogo from "./images/Capture.JPG";
import useAppContext from './../../Infrastructure/state/useAppContext';

function Plans() {

  const { equalPlan, setEqualPlan } = useContext(MyContext);
  const { icons, setIcons } = useContext(MyContext);
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate()

  const isLoggedIn = () => {
    try {
      if (state.auth != null) {
        if (state.auth.authorisation != null || state.auth.authorisation != undefined) {
          return true
        }
      }
    } catch (e) {
      return false;
    }
  }
  
  useEffect(() => {
    if (!state.isLoaded) {
      return;
    }
    
    // if (!isLoggedIn()) {
    //   navigate("/registering")
    // }


    const addwin = window.location.href.split('/');
    const lengAdd = addwin.length;
    const numHref = addwin[lengAdd - 1];
    setIcons(numHref);

  }, [state.isLoaded])

  const realclick = () => {
    setEqualPlan('real')
    dispatch({
      type: "USER_TEMP",
      payload: {
          ...state.temp_user,
          plan : equalPlan
      }
  })
  }
  const judgeclick = () => {
    setEqualPlan('judge')
    dispatch({
      type: "USER_TEMP",
      payload: {
          ...state.temp_user,
          plan : equalPlan
      }
  })
  }
  return (
    < >

      <div className="bg-fixet plan-fix">
        <Sidebar />
        <HeaderRegister />
        <div className="plansfield plan-fill">

          <div className="realorfake heigh-100">
            <div className="selection heigh-100">
              <div className="items-plan resik">
                <img className="img-plan risking" src={realiLogo} />
              </div>
              <div className="items-plan">
                <div className="font-plans">
                  <span style={{ fontSize: 20, fontWeight: "bold" }}>فروشنده حقیقی</span>
                </div>
                <div className="font-plans">
                  <span style={{ fontSize: 12, fontWeight: "normal" }}>
                    فروشنده حقیقی، فارغ از هرگونه شرکتی، به شکل مستقل کسب و کار خود را پیش می برد. این نوع کسب و کار تنها نام و اطلاعات شخصی فرد را نیاز دارد.
                  </span>
                </div>
                <div className="divbutt">
                  <Link to='/registering/2' >
                    <button className="btn btn-primary btn-phone f-but" onClick={realclick} >
                      حقیقی
                    </button></Link>
                </div>

              </div>
            </div>
            <div className="selection heigh-100">
              <div className="items-plan">
                <img className="img-plan addimg" src={judgeLogo} />
              </div>
              <div className="items-plan">
                <div className="font-plans">
                  <span style={{ fontSize: 20, fontWeight: "bold" }}>فروشنده حقوقی</span>
                </div>
                <div className="font-plans">
                  <span style={{ fontSize: 12, fontWeight: 'normal' }}>
                    فروشنده حقوقی مجموعه ها و شرکت هایی هستند که به طور قانونی مشغول به فعالیت در کسب و کار خود می باشند. این نوع کسب و کار نیازمند کد اقتصادی، کد رهگیری مالیاتی و... است.
                  </span>
                </div>
                <div className="divbutt">
                  <Link to='/registering/2' >
                    <button className="btn btn-primary btn-phone f-but" onClick={judgeclick} >
                      حقوقی
                    </button>
                  </Link>
                </div>


              </div>
            </div>
          </div>
          <FooterPlan />
        </div>

      </div>
    </>

  );
}

export default Plans;
