import React from "react";
import './Plans.css';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { MyContext } from "../ContexApi/MyContext";
import { useContext, useState, useRef, useEffect } from "react";
import FooterPlan from "../SideBar/FooterPlan";
import { Valid_Number, Valid_MeliCode, Valid_eq, Valid_maliat } from "../regEX/regEX";
import { FaFolderPlus } from "react-icons/fa";




function PlanB1() {

  const [company_name, setCompany_name] = useState('');
  const [company_eq, setCompany_eq] = useState('');
  const [company_code, setCompany_code] = useState('');
  const [company_number, setCompany_number] = useState('');
  const [sec_name, setSec_name] = useState('');
  const [sec_family, setSec_family] = useState('');
  const [sec_code, setSec_code] = useState('');


  const [note, setNote] = useState('');
  const [note1, setNote1] = useState('');
  const [note2, setNote2] = useState('');
  const [note3, setNote3] = useState('');
  const [note4, setNote4] = useState('');
  const [note5, setNote5] = useState('');
  const [note6, setNote6] = useState('');
  const [imagesrc, setImagesrc] = useState('');
  const [imagesrc1, setImagesrc1] = useState('');
  const [notice_Upload, setNotice_Upload] = useState('');
  const [notice_Upload1, setNotice_Upload1] = useState('');

  const newspaper = useRef();
  const poruf = useRef();
  const newspaperFile = useRef();
  const porufFile = useRef();
  const newspaperFileImage = useRef();
  const porufFileImage = useRef();



  useEffect(() => {

    newspaper.current.addEventListener("click", function () {
      newspaperFile.current.click()
    })

    poruf.current.addEventListener("click", function () {
      porufFile.current.click()
    })

    newspaperFile.current.addEventListener("change", function (event) {
      let fileReader = new FileReader();

      fileReader.onloadend = function () {
        let result = fileReader.result
        setImagesrc(result)
      }

      fileReader.readAsDataURL(event.target.files[0]);

    })

    porufFile.current.addEventListener("change", function (event) {
      let fileReader = new FileReader();

      fileReader.onloadend = function () {
        let result = fileReader.result
        setImagesrc1(result)
      }

      fileReader.readAsDataURL(event.target.files[0]);

    })

  }, [])

  const { icon2, setIcon2 } = useContext(MyContext);
  const { chaLocate, setChaLocate } = useContext(MyContext);
  const navigate = useNavigate();
  const clickservis = () => {
    if (company_name === "") { setNote('نام شرکت خود را وارد کنید') } else { setNote("") }
    if (!Valid_eq.test(company_eq)) { setNote1('کد اقتصادی را به درستی وارد کنید') } else { setNote1("") }
    if (!Valid_maliat.test(company_code)) { setNote2('کد رهگیری مالیاتی را به درستی وارد نمایید') } else { setNote2("") }
    if (company_number === "") { setNote3('شماره تلفن خودرا وارد نمایید') } else { setNote3("") }
    if (sec_name === "") { setNote4('نام خانوادگی خود را وارد کنید') } else { setNote4("") }
    if (sec_family === "") { setNote5('نام خود را وارد کنید') } else { setNote5("") }
    if (imagesrc === "") { setNotice_Upload("عکس خواسته شده را آپلود کنید") } else { setNotice_Upload("") }
    if (imagesrc1 === "") { setNotice_Upload1("عکس خواسته شده را آپلود کنید") } else { setNotice_Upload1("") }
    if (!Valid_MeliCode.test(sec_code)) { setNote6("کد ملی خود را به درستی وارد نمایید") } else { setNote6("") }
    if (company_name != "" && Valid_eq.test(company_eq) && Valid_maliat.test(company_code) && company_number != "" && sec_name != "" && Valid_MeliCode.test(sec_code) && sec_family != "" && imagesrc != "" && imagesrc1 != "") {

      // dispatch({
      //   type: "USER_TEMP",
      //   payload: {
      //     ...state.temp_user,
      //     plan: 'judge',
      //     company_name: company_name,
      //     code_rahgiri: company_code,
      //     code_company: company_eq,
      //     phone: company_number,
      //     sec_name: sec_name,
      //     sec_family: sec_family,
      //     sec_code: sec_code,
      //     image_newspaper: imagesrc,
      //     last_changeImage: imagesrc1,
      //   }
      // })

      setChaLocate("JudgeLocation")
      navigate('/registering/3')
    }
    else {
      return;
    }
  }

  return (
    <div className="plana height-600">
      <div className="map-consoles heigh-9">
        <div className="banner-plan">
          <span>شخص حقوقی</span>
        </div>
        <div className="list-input wid-20" >
          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">نام شرکت</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder=" نام شرکت" aria-describedby="defaultFormControlHelp" value={company_name} onChange={e => setCompany_name(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note}
              </div>
            </div>

          </div>

          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">کد اقتصادی</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="کد اقتصادی" aria-describedby="defaultFormControlHelp" value={company_eq} onChange={e => setCompany_eq(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note1}
              </div>
            </div>

          </div>

        </div>
        <div className="list-input wid-20" >
          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">کد رهگیری مالیاتی</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="کد رهگیری مالیاتی" aria-describedby="defaultFormControlHelp" value={company_code} onChange={e => setCompany_code(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note2}
              </div>
            </div>

          </div>

          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">شماره تماس</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="شماره تماس کاری" aria-describedby="defaultFormControlHelp" value={company_number} onChange={e => setCompany_number(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note3}
              </div>
            </div>

          </div>

        </div>
        <div className="list-input wid-20" >
          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">نام نماینده</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder=" نام نماینده " aria-describedby="defaultFormControlHelp" value={sec_name} onChange={e => setSec_name(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note4}
              </div>
            </div>

          </div>

          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">نام خانوادگی نماینده</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="نام خانوادگی" aria-describedby="defaultFormControlHelp" value={sec_family} onChange={e => setSec_family(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note5}
              </div>
            </div>

          </div>

        </div>
        <div className="list-input wid-20" >
          <div className="input-label" >
            <div className="wid-94">
              <label htmlFor="defaultFormControlInput" className="form-label">کد ملی نماینده</label>
              <input type="text" className="form-control" id="defaultFormControlInput" placeholder="کد ملی" aria-describedby="defaultFormControlHelp" value={sec_code} onChange={e => setSec_code(e.target.value)} />
              <div id="defaultFormControlHelp" className="form-text error-valid">
                {note6}
              </div>

            </div>

          </div>

          <div className="input-label" >
            {/* <div className="mb-3">
                <label htmlFor="formFile" className="form-label">آپلود تصویر کارت ملی</label>
                <input className="form-control" type="file" id="formFile"  />
              </div>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">آپلود تصویر کارت ملی</label>
                <input className="form-control" type="file" id="formFile"  />
              </div> */}

          </div>

        </div>
        <div className="list-input wid-20" >
          <div className="input-label" >

            <label htmlFor="formFile" className="form-label"> تصویر روزنامه کاری</label>
            <input type="file" ref={newspaperFile} style={{ display: "none" }} />
            <button type="button" className="btn btn-outline-primary waves-effect wid-94 bord-ght" ref={newspaper} >
              <FaFolderPlus className="ti-xs ti ti-bell pruficon" />آپلود عکس</button>
            <div id="defaultFormControlHelp" className="form-text error-valid">
              {notice_Upload}
            </div>

          </div>

          <div className="input-label" >
            <label htmlFor="formFile" className="form-label"> تصویر تغییرات مدیریتی</label>
            <input type="file" ref={porufFile} style={{ display: "none" }} />
            <button type="button" className="btn btn-outline-primary waves-effect wid-94 bord-ght" ref={poruf} >
              <FaFolderPlus className="ti-xs ti ti-bell pruficon" />آپلود عکس</button>
            <div id="defaultFormControlHelp" className="form-text error-valid">
              {notice_Upload1}
            </div>

          </div>

        </div>

        <div className="btn-list-input" >
          <button className="btn btn-primary btn-plan" onClick={clickservis} >مرحله بعد</button>
        </div>
      </div>
      <FooterPlan />
    </div>
  );
}

export default PlanB1;
